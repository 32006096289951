import {
  ChannelType,
  LmnApprovalMode,
  LmnVerificationStatus,
  OrderDetailsQuery,
  OrderStatus,
} from '@/common/graphql';

export type OrderTrackingType = {
  order_id?: string;
  sales_channel_id?: string;
  sales_channel_type?: ChannelType | null;
  testing?: boolean;
  is_charged?: boolean;
  has_typeform?: boolean;
  intake_form_score?: number | null;
  has_lmn?: boolean;
  lmn_verification_status?: LmnVerificationStatus | null;
  lmn_verification_approval_mode?: LmnApprovalMode | null;
  dollars?: any | null;
};

export const toOrderTrackPayload = (
  order?: OrderDetailsQuery['order']
): OrderTrackingType => ({
  order_id: order?.businessId,
  sales_channel_id: order?.salesChannel?.businessId,
  sales_channel_type: order?.salesChannel?.type,
  testing: order?.isTesting,
  is_charged: order?.status === OrderStatus.Charged,
  has_typeform: !!order?.intakeForm,
  intake_form_score: order?.intakeForm?.score,
  has_lmn: !!order?.lmnVerification,
  lmn_verification_status: order?.lmnVerification?.status,
  lmn_verification_approval_mode: order?.lmnVerification?.approvalMode,
  dollars: order?.totalAmount,
});

export function toBusinessPageName(pathname = '<undefined>'): string {
  switch (pathname) {
    case '/':
      return 'Home';
    case '/login':
      return 'Login';
    case '/reset-password':
      return 'Reset Password';
    case '/checkout/intro/[orderId]':
      return 'Payment Flow - Intro';
    case '/checkout/payment/[orderId]':
      return 'Payment Flow - Payment';
    case '/checkout/intake-form/[orderId]':
      return 'Payment Flow - Intake Form';
    case '/checkout/result/[orderId]':
      return 'Payment Flow - Completion';
    case '/order/[orderId]':
      return 'Order Detail';
    case '/merchants/payments/[storeId]':
      return 'Merchant - Channel Detail';
    case '/dashboard':
      return 'Client - Dashboard';
    case '/providers/evaluations':
      return 'HCP - Dashboard';
    case '/lmn-verification/[lmnVerificationId]':
      return 'HCP - Verification Detail';
    case '/qualify/[sessionId]':
      return 'Qualify - Create Session';
    case '/qualify/session/[sessionId]':
      return 'Qualify - Evaluation Form';
    case '/qualify/session/[sessionId]/result':
      return 'Qualify - Result';
    case '/qualify/session/[sessionId]/payment':
      return 'Qualify - Payment';
    case '/qualify/session/[sessionId]/payment/result':
      return 'Qualify - Payment Result';
    case '/pre-qualify/[sessionId]':
      return 'Pre-qualify - Create Session';
    case '/pre-qualify/session/[sessionId]':
      return 'Pre-qualify - Evaluation Form';
    case '/pre-qualify/session/[sessionId]/result':
      return 'Pre-qualify - Result';
    case '/[affiliateOrg]/qualify/[merchantId]':
      return 'Affiliate Qualify - Create Session';
    default:
      return pathname;
  }
}
