import { captureException } from '@/common/lib/Sentry';
import { toBusinessPageName } from '@/features/tracking/TrackingUtils';
import { Box, Button, Flex } from '@chakra-ui/react';
import React from 'react';
import { MixpanelAPI } from '../common/lib/Mixpanel';

type Props = {
  pathname?: string;
  children?: any;
};

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI
    console.error('has error', error);
    return { hasError: true };
  }
  componentDidCatch(error: any, errorInfo: any) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
    MixpanelAPI.track(
      `Page - ${toBusinessPageName(this.props?.pathname)} - Error`
    );
    captureException(error);
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Flex height="calc(100vh - 290px)">
          <Box m={'auto'}>
            <h2>Oops, there is an error!</h2>
            <Button onClick={() => this.setState({ hasError: false })}>
              Try again?
            </Button>
          </Box>
        </Flex>
      );
    }

    // Return children components in case of no error
    return this.props.children;
  }
}
