/** Saves new user session */
export const saveSession = async (refreshToken: string) => {
  return fetch(`${process.env.NEXT_PUBLIC_APP_URL}/api/ss`, {
    method: 'POST',
    body: JSON.stringify({
      refreshToken: refreshToken,
    }),
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

/** Loads user session if it exists */
export const loadSession = async (cookie?: string) => {
  const loadSessionResponse = await fetch(
    `${process.env.NEXT_PUBLIC_APP_URL}/api/ls`,
    {
      method: 'GET',
      headers: {
        Cookie: cookie || '',
      },
    }
  );
  return await loadSessionResponse.json();
};

/** Logs user out of TM website */
export const logout = async () => {
  const response = await fetch(
    `${process.env.NEXT_PUBLIC_APP_URL}/api/logout`,
    {
      method: 'POST',
    }
  );
  return await response.json();
};
