import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: any;
  GenericScalar: any;
  JSONString: any;
  UUID: string;
  Upload: any;
};

export type AffiliateMerchantResponse = {
  __typename?: 'AffiliateMerchantResponse';
  linkableQualId: Scalars['String'];
  merchantName: Scalars['String'];
};

export enum AffiliateOrganizationMemberRole {
  Owner = 'Owner',
  Staff = 'Staff',
}

export type AffiliateOrganizationMemberType = {
  __typename?: 'AffiliateOrganizationMemberType';
  affiliateOrganization: AffiliateOrganizationType;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  role?: Maybe<AffiliateOrganizationMemberRole>;
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

export type AffiliateOrganizationType = {
  __typename?: 'AffiliateOrganizationType';
  affiliateOrganizationMembers: Array<AffiliateOrganizationMemberType>;
  consumerLmnPriceDollars: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  defaultQualificationFormId: Scalars['String'];
  defaultRecommendedProducts?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lmnReferralFeeDollars?: Maybe<Scalars['Decimal']>;
  merchants: Array<MerchantType>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type AffiliateSignUp = {
  __typename?: 'AffiliateSignUp';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  session?: Maybe<UserSessionType>;
  token?: Maybe<Scalars['String']>;
};

export type BasicOrderType = {
  __typename?: 'BasicOrderType';
  businessId: Scalars['UUID'];
  cancelUrl?: Maybe<Scalars['String']>;
  charge?: Maybe<OrderChargeType>;
  createdAt: Scalars['DateTime'];
  evaluationStatus?: Maybe<Scalars['String']>;
  externalId?: Maybe<Scalars['String']>;
  formVariables?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isSubscription: Scalars['Boolean'];
  isTesting: Scalars['Boolean'];
  merchantStripeId?: Maybe<Scalars['String']>;
  orderItems: Array<OrderItemType>;
  paidWith?: Maybe<CardType>;
  recurring?: Maybe<Scalars['String']>;
  salesChannel?: Maybe<SalesChannelType>;
  status?: Maybe<OrderStatus>;
  successUrl?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Decimal'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  useAlternativeCard?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type CancelOrder = {
  __typename?: 'CancelOrder';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export enum CardType {
  HsaOrFsaCard = 'HsaOrFsaCard',
  NormalCard = 'NormalCard',
}

export enum ChannelType {
  Shopify = 'Shopify',
  Standalone = 'Standalone',
  StandaloneConsumer = 'StandaloneConsumer',
}

export type CheckPasswordReset = {
  __typename?: 'CheckPasswordReset';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConfirmAndChargeOrderForNormalCard = {
  __typename?: 'ConfirmAndChargeOrderForNormalCard';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export type ConfirmOrder = {
  __typename?: 'ConfirmOrder';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export type ConfirmPasswordReset = {
  __typename?: 'ConfirmPasswordReset';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConfirmQualificationSessionPayment = {
  __typename?: 'ConfirmQualificationSessionPayment';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type ConnectSalesChannelToMerchant = {
  __typename?: 'ConnectSalesChannelToMerchant';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  salesChannel?: Maybe<SalesChannelType>;
};

export type CreateLetterOfMedicalNecessity = {
  __typename?: 'CreateLetterOfMedicalNecessity';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  lmnId?: Maybe<Scalars['String']>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type CreateOrderFromPriceLink = {
  __typename?: 'CreateOrderFromPriceLink';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  orderBusinessId?: Maybe<Scalars['String']>;
};

export type CreateQualificationSessionFromAffiliate = {
  __typename?: 'CreateQualificationSessionFromAffiliate';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  formId: Scalars['String'];
  qsBusinessId: Scalars['String'];
};

export type CreateQualificationSessionFromLink = {
  __typename?: 'CreateQualificationSessionFromLink';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  formId: Scalars['String'];
  qsBusinessId: Scalars['String'];
};

export type CreateStripeLink = {
  __typename?: 'CreateStripeLink';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  url?: Maybe<Scalars['String']>;
};

export enum CustomerStatus {
  Customer = 'customer',
  Prospect = 'prospect',
  Unknown = 'unknown',
}

export type EndSubscription = {
  __typename?: 'EndSubscription';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  order?: Maybe<OrderType>;
};

export enum EnvFeature {
  EnableConsumerPaidTest = 'ENABLE_CONSUMER_PAID_TEST',
  EnableLmnTemplates = 'ENABLE_LMN_TEMPLATES',
}

export type ErrorType = {
  __typename?: 'ErrorType';
  code?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EvaluationFormDataAnswerType = {
  __typename?: 'EvaluationFormDataAnswerType';
  questionId?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type EvaluationFormDataType = {
  __typename?: 'EvaluationFormDataType';
  answers?: Maybe<Array<Maybe<EvaluationFormDataAnswerType>>>;
  formId?: Maybe<Scalars['String']>;
};

export type EvaluationFormResponse = {
  response: Array<EvaluationFormResponseAnswer>;
};

export type EvaluationFormResponseAnswer = {
  questionId: Scalars['String'];
  values: Array<Scalars['String']>;
};

export enum EvaluationStatus {
  Failed = 'Failed',
  Success = 'Success',
}

export type EvaluationType = {
  __typename?: 'EvaluationType';
  businessId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  formId: Scalars['String'];
  id: Scalars['ID'];
  lmnVerification?: Maybe<LmnVerificationType>;
  qualificationSession?: Maybe<QualificationSessionGrapheneType>;
  score: Scalars['Int'];
  status?: Maybe<EvaluationStatus>;
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

export enum Feature {
  CartListener = 'CART_LISTENER',
  CcOnboarding = 'CC_ONBOARDING',
}

export type HealthCareProviderType = {
  __typename?: 'HealthCareProviderType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

export type IntakeFormDataType = {
  __typename?: 'IntakeFormDataType';
  answers?: Maybe<Array<Maybe<Scalars['String']>>>;
  content?: Maybe<Scalars['String']>;
};

export enum IntakeFormStatus {
  Failed = 'Failed',
  Initial = 'Initial',
  Success = 'Success',
}

export type IntakeFormType = {
  __typename?: 'IntakeFormType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lmnVerification?: Maybe<LmnVerificationType>;
  score?: Maybe<Scalars['Int']>;
  status?: Maybe<IntakeFormStatus>;
  typeformId?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

export enum LmnApprovalMode {
  Auto = 'Auto',
  Manual = 'Manual',
}

export type LmnDiagnosisType = {
  __typename?: 'LMNDiagnosisType';
  diagnosisId: Scalars['Int'];
  name: Scalars['String'];
};

export enum LmnVerificationStatus {
  Pending = 'Pending',
  Rejected = 'Rejected',
  Verified = 'Verified',
}

export type LmnVerificationType = {
  __typename?: 'LMNVerificationType';
  approvalMode?: Maybe<LmnApprovalMode>;
  client: UserType;
  createdAt: Scalars['DateTime'];
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lmn?: Maybe<LetterOfMedicalNecessityType>;
  merchantName?: Maybe<Scalars['String']>;
  order?: Maybe<BasicOrderType>;
  reviewedAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<LmnVerificationStatus>;
  updatedAt: Scalars['DateTime'];
  verifiedBy?: Maybe<HealthCareProviderType>;
};

export type LetterOfMedicalNecessityType = {
  __typename?: 'LetterOfMedicalNecessityType';
  createdAt: Scalars['DateTime'];
  expiredDate: Scalars['Date'];
  id: Scalars['ID'];
  issuedBy?: Maybe<HealthCareProviderType>;
  issuedDate: Scalars['Date'];
  patient?: Maybe<UserType>;
  updatedAt: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type LmnVerificationAssignment = {
  __typename?: 'LmnVerificationAssignment';
  lmnVerification?: Maybe<LmnVerificationType>;
};

export type LmnVerificationDetailType = {
  __typename?: 'LmnVerificationDetailType';
  evaluationFormData?: Maybe<EvaluationFormDataType>;
  intakeFormData?: Maybe<Array<Maybe<IntakeFormDataType>>>;
  lmnVerification?: Maybe<LmnVerificationType>;
  merchantHasRecommendedProducts: Scalars['Boolean'];
  merchantId: Scalars['String'];
  merchantName: Scalars['String'];
  nextUnclaimedRecordId?: Maybe<Scalars['String']>;
  previousUnclaimedRecordId?: Maybe<Scalars['String']>;
};

export type Login = {
  __typename?: 'Login';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  session?: Maybe<UserSessionType>;
  token: Scalars['String'];
};

export type MagicLinkLogin = {
  __typename?: 'MagicLinkLogin';
  ok?: Maybe<Scalars['Boolean']>;
  refreshToken?: Maybe<Scalars['String']>;
};

export enum MainOrderPaymentMethodChoices {
  Stripe = 'STRIPE',
}

export enum MainOrderPaymentStatusChoices {
  Paid = 'PAID',
  Pending = 'PENDING',
  Unpaid = 'UNPAID',
}

export enum MerchantCategory {
  Affiliate = 'Affiliate',
  Standard = 'Standard',
}

export enum MerchantMemberRole {
  Owner = 'Owner',
  Staff = 'Staff',
}

export type MerchantMemberType = {
  __typename?: 'MerchantMemberType';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  merchant: MerchantType;
  role?: Maybe<MerchantMemberRole>;
  updatedAt: Scalars['DateTime'];
  user: UserType;
};

export type MerchantOnboardingConfig = {
  __typename?: 'MerchantOnboardingConfig';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  invoicingEnabled?: Maybe<Scalars['Boolean']>;
  merchantEmail: Scalars['String'];
  needsCcEntry?: Maybe<Scalars['Boolean']>;
  onboardingType: MerchantOnboardingType;
  showQualifications?: Maybe<Scalars['Boolean']>;
};

export enum MerchantOnboardingType {
  Qualification = 'Qualification',
  ShopifyPayments = 'ShopifyPayments',
  StandalonePayments = 'StandalonePayments',
}

export enum MerchantStatus {
  Approved = 'Approved',
  Pending = 'Pending',
}

export type MerchantType = {
  __typename?: 'MerchantType';
  affiliateOrganization?: Maybe<AffiliateOrganizationType>;
  affiliateOrganizationName?: Maybe<Scalars['String']>;
  affiliateOrganizationSlug?: Maybe<Scalars['String']>;
  analyticsIgnore: Scalars['Boolean'];
  category: MerchantCategory;
  consumerLmnPriceDollars: Scalars['Decimal'];
  consumerQualificationFormId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  defaultRecommendedProducts?: Maybe<Scalars['String']>;
  featureFlags: Array<Feature>;
  id: Scalars['ID'];
  installShopifyAppUrl?: Maybe<Scalars['String']>;
  invoicingEnabled: Scalars['Boolean'];
  klaviyoListId?: Maybe<Scalars['String']>;
  linkableQualificationId: Scalars['String'];
  merchantMembers: Array<MerchantMemberType>;
  merchantType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentContractSignedAt?: Maybe<Scalars['Date']>;
  phoneNumber?: Maybe<Scalars['String']>;
  publicId?: Maybe<Scalars['String']>;
  qualificationSessions: Array<QualificationSessionGrapheneType>;
  qualificationSpamEmail?: Maybe<Scalars['String']>;
  qualificationsContractSignedAt?: Maybe<Scalars['Date']>;
  salesChannels: Array<SalesChannelType>;
  status?: Maybe<MerchantStatus>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  stripeOnboardingComplete?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['DateTime'];
  webhookUrl?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  affiliateSignUp?: Maybe<AffiliateSignUp>;
  assignHcpToLmnVerification?: Maybe<LmnVerificationAssignment>;
  cancelOrder?: Maybe<CancelOrder>;
  checkPasswordReset?: Maybe<CheckPasswordReset>;
  confirmAndChargeOrderForNormalCard?: Maybe<ConfirmAndChargeOrderForNormalCard>;
  confirmOrder?: Maybe<ConfirmOrder>;
  confirmPasswordReset?: Maybe<ConfirmPasswordReset>;
  confirmQualificationSessionPayment?: Maybe<ConfirmQualificationSessionPayment>;
  connectSalesChannelToMerchant?: Maybe<ConnectSalesChannelToMerchant>;
  createLetterOfMedicalNecessity?: Maybe<CreateLetterOfMedicalNecessity>;
  createOrderFromPriceLink?: Maybe<CreateOrderFromPriceLink>;
  createQualificationSessionFromAffiliate?: Maybe<CreateQualificationSessionFromAffiliate>;
  createQualificationSessionFromLink?: Maybe<CreateQualificationSessionFromLink>;
  createStripeLink?: Maybe<CreateStripeLink>;
  endSubscription?: Maybe<EndSubscription>;
  login?: Maybe<Login>;
  magicLinkLogin?: Maybe<MagicLinkLogin>;
  refreshStripeStatus?: Maybe<RefreshStripeStatus>;
  refreshToken?: Maybe<Refresh>;
  requestPasswordReset?: Maybe<RequestPasswordReset>;
  resolveQualificationClientSecret?: Maybe<ResolveQualificationSessionClientSecret>;
  revokeSession?: Maybe<RevokeSession>;
  saveCustomerPaymentMethod?: Maybe<SaveCustomerPaymentMethod>;
  saveEvaluation?: Maybe<SaveEvaluation>;
  saveMerchantPaymentMethod?: Maybe<SaveMerchantPaymentMethod>;
  savePartialEvaluation?: Maybe<SavePartialEvaluation>;
  saveSession?: Maybe<SaveSession>;
  scoreEvaluation?: Maybe<ScoreEvaluation>;
  signUp?: Maybe<SignUp>;
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  tryFinalizeShopifyPayment?: Maybe<TryFinalizeShopifyPayment>;
  updateLetterOfMedicalNecessity?: Maybe<UpdateLetterOfMedicalNecessity>;
  verifyLetterOfMedicalNecessity?: Maybe<VerifyLetterOfMedicalNecessity>;
};

export type MutationAffiliateSignUpArgs = {
  companyName: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
  slug: Scalars['String'];
};

export type MutationAssignHcpToLmnVerificationArgs = {
  lmnVerificationId: Scalars['String'];
};

export type MutationCancelOrderArgs = {
  businessId: Scalars['String'];
};

export type MutationCheckPasswordResetArgs = {
  emailB64: Scalars['String'];
  token: Scalars['String'];
};

export type MutationConfirmAndChargeOrderForNormalCardArgs = {
  businessId: Scalars['String'];
  email: Scalars['String'];
};

export type MutationConfirmOrderArgs = {
  businessId: Scalars['String'];
  email: Scalars['String'];
  paidWith?: InputMaybe<CardType>;
};

export type MutationConfirmPasswordResetArgs = {
  emailB64: Scalars['String'];
  password1: Scalars['String'];
  password2: Scalars['String'];
  token: Scalars['String'];
};

export type MutationConfirmQualificationSessionPaymentArgs = {
  qsBusinessId: Scalars['String'];
};

export type MutationConnectSalesChannelToMerchantArgs = {
  merchantId: Scalars['Int'];
  salesChannelBusinessId: Scalars['String'];
};

export type MutationCreateLetterOfMedicalNecessityArgs = {
  expiredDate: Scalars['Date'];
  issuedDate: Scalars['Date'];
  lmnDiagnosisId?: InputMaybe<Scalars['Int']>;
  lmnFile?: InputMaybe<Scalars['Upload']>;
  merchantId: Scalars['Int'];
  patientId: Scalars['Int'];
};

export type MutationCreateOrderFromPriceLinkArgs = {
  linkablePriceId: Scalars['String'];
};

export type MutationCreateQualificationSessionFromAffiliateArgs = {
  affiliateOrgSlug: Scalars['String'];
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  salesChannelQualId: Scalars['String'];
  utm?: InputMaybe<Scalars['String']>;
};

export type MutationCreateQualificationSessionFromLinkArgs = {
  externalUserId?: InputMaybe<Scalars['String']>;
  linkableQualId: Scalars['String'];
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  sessionType?: InputMaybe<QualificationSessionType>;
  signature?: InputMaybe<Scalars['String']>;
  successAction?: InputMaybe<QualificationSuccessActionType>;
  utm?: InputMaybe<Scalars['String']>;
};

export type MutationCreateStripeLinkArgs = {
  merchantId?: InputMaybe<Scalars['Int']>;
  returnUrl?: InputMaybe<Scalars['String']>;
};

export type MutationEndSubscriptionArgs = {
  orderBusinessId: Scalars['String'];
};

export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationMagicLinkLoginArgs = {
  sesameToken: Scalars['String'];
};

export type MutationRefreshStripeStatusArgs = {
  merchantId?: InputMaybe<Scalars['Int']>;
};

export type MutationRefreshTokenArgs = {
  refreshToken?: InputMaybe<Scalars['String']>;
};

export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};

export type MutationResolveQualificationClientSecretArgs = {
  qsBusinessId: Scalars['String'];
};

export type MutationRevokeSessionArgs = {
  sessionId: Scalars['String'];
};

export type MutationSaveCustomerPaymentMethodArgs = {
  businessId: Scalars['String'];
  paymentMethodId: Scalars['String'];
};

export type MutationSaveEvaluationArgs = {
  formId: Scalars['String'];
  orderBusinessId?: InputMaybe<Scalars['String']>;
  qsBusinessId?: InputMaybe<Scalars['String']>;
  userResponse: EvaluationFormResponse;
};

export type MutationSaveMerchantPaymentMethodArgs = {
  billingUserName: Scalars['String'];
  merchantId: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
};

export type MutationSavePartialEvaluationArgs = {
  formId: Scalars['String'];
  orderBusinessId?: InputMaybe<Scalars['String']>;
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  qsBusinessId?: InputMaybe<Scalars['String']>;
  userResponse: EvaluationFormResponseAnswer;
};

export type MutationSaveSessionArgs = {
  refreshToken: Scalars['String'];
  sessionId: Scalars['String'];
};

export type MutationScoreEvaluationArgs = {
  formId: Scalars['String'];
  qsBusinessId?: InputMaybe<Scalars['String']>;
  userResponse: EvaluationFormResponse;
};

export type MutationSignUpArgs = {
  asMerchant?: Scalars['Boolean'];
  email: Scalars['String'];
  merchantName?: InputMaybe<Scalars['String']>;
  merchantType?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  password: Scalars['String'];
};

export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type MutationTryFinalizeShopifyPaymentArgs = {
  businessId: Scalars['String'];
};

export type MutationUpdateLetterOfMedicalNecessityArgs = {
  comment?: InputMaybe<Scalars['String']>;
  lmnId?: InputMaybe<Scalars['Int']>;
  lmnVerificationId: Scalars['Int'];
};

export type MutationVerifyLetterOfMedicalNecessityArgs = {
  expiredAt: Scalars['Date'];
  lmnId?: InputMaybe<Scalars['Int']>;
  status: LmnVerificationStatus;
  verificationId: Scalars['Int'];
};

export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export enum OrderChargeStatus {
  Authorized = 'Authorized',
  Captured = 'Captured',
  Failed = 'Failed',
  PartialRefunded = 'PartialRefunded',
  Refunded = 'Refunded',
  Void = 'Void',
}

export type OrderChargeType = {
  __typename?: 'OrderChargeType';
  applicationFee?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  receiptUrl?: Maybe<Scalars['String']>;
  refundedAmount: Scalars['Decimal'];
  status?: Maybe<OrderChargeStatus>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type OrderItemType = {
  __typename?: 'OrderItemType';
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  paymentMethodId?: Maybe<Scalars['String']>;
  price: Scalars['Decimal'];
  quantity: Scalars['Int'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscriptionPayload?: Maybe<Scalars['JSONString']>;
  updatedAt: Scalars['DateTime'];
};

export enum OrderStatus {
  Cancelled = 'Cancelled',
  Charged = 'Charged',
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Refunded = 'Refunded',
}

export type OrderType = {
  __typename?: 'OrderType';
  applicationFeeAmount: Scalars['Decimal'];
  businessId: Scalars['UUID'];
  cancelUrl?: Maybe<Scalars['String']>;
  charge?: Maybe<OrderChargeType>;
  createdAt: Scalars['DateTime'];
  evaluation?: Maybe<EvaluationType>;
  evaluationStatus?: Maybe<Scalars['String']>;
  externalExtraInformation?: Maybe<Scalars['JSONString']>;
  externalId?: Maybe<Scalars['String']>;
  externalPaymentGid?: Maybe<Scalars['String']>;
  externalPaymentId?: Maybe<Scalars['String']>;
  formVariables?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  intakeForm?: Maybe<IntakeFormType>;
  isSubscription: Scalars['Boolean'];
  isTesting: Scalars['Boolean'];
  lmnVerification?: Maybe<LmnVerificationType>;
  merchantStripeId?: Maybe<Scalars['String']>;
  orderCharges: Array<OrderChargeType>;
  orderItems: Array<OrderItemType>;
  paidAt?: Maybe<Scalars['DateTime']>;
  paidWith?: Maybe<CardType>;
  paymentMethod?: Maybe<MainOrderPaymentMethodChoices>;
  paymentPendingExpiresAt?: Maybe<Scalars['DateTime']>;
  paymentStatus: MainOrderPaymentStatusChoices;
  recurring?: Maybe<Scalars['String']>;
  salesChannel?: Maybe<SalesChannelType>;
  status?: Maybe<OrderStatus>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  stripeOldPaymentIntentId?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  successUrl?: Maybe<Scalars['String']>;
  totalAmount: Scalars['Decimal'];
  type?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  useAlternativeCard?: Maybe<Scalars['Boolean']>;
  user?: Maybe<UserType>;
};

export type PaginatedLmnVerificationsType = {
  __typename?: 'PaginatedLmnVerificationsType';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
  verifications?: Maybe<Array<LmnVerificationType>>;
};

export type PaginatedMerchantQualifications = {
  __typename?: 'PaginatedMerchantQualifications';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
  qualifications: Array<QualificationSessionGrapheneType>;
};

export type PaginatedOrders = {
  __typename?: 'PaginatedOrders';
  count: Scalars['Int'];
  currentPage: Scalars['Int'];
  orders: Array<BasicOrderType>;
  pageSize: Scalars['Int'];
};

export type QualificationSessionGrapheneType = {
  __typename?: 'QualificationSessionGrapheneType';
  businessId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  customerStatus?: Maybe<CustomerStatus>;
  evaluation?: Maybe<EvaluationType>;
  externalSignature?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  failureRedirectUrl?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Decimal']>;
  formId: Scalars['String'];
  id: Scalars['ID'];
  ip?: Maybe<Scalars['String']>;
  klaviyoLastSyncedOn?: Maybe<Scalars['DateTime']>;
  lmnReviewedAt?: Maybe<Scalars['DateTime']>;
  lmnVerification?: Maybe<LmnVerificationType>;
  merchant?: Maybe<MerchantType>;
  merchantName?: Maybe<Scalars['String']>;
  persistentBrowserId?: Maybe<Scalars['String']>;
  retryUrl?: Maybe<Scalars['String']>;
  salesChannel?: Maybe<SalesChannelType>;
  salesChannelType: ChannelType;
  sessionType?: Maybe<QualificationSessionType>;
  status?: Maybe<Scalars['String']>;
  stripePaymentId?: Maybe<Scalars['String']>;
  successAction?: Maybe<QualificationSuccessActionType>;
  successRedirectUrl?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<UserType>;
  utm?: Maybe<Scalars['String']>;
  variables?: Maybe<Scalars['String']>;
};

export enum QualificationSessionType {
  ConsumerQualification = 'ConsumerQualification',
  MerchantPreQualification = 'MerchantPreQualification',
  MerchantQualification = 'MerchantQualification',
}

export enum QualificationSuccessActionType {
  CheckoutWithTm = 'CheckoutWithTM',
  Continue = 'Continue',
  Noop = 'Noop',
  Redirect = 'Redirect',
}

export type Query = {
  __typename?: 'Query';
  affiliateMerchantsByOrganization: Array<AffiliateMerchantResponse>;
  currentHcpLmnVerifications?: Maybe<PaginatedLmnVerificationsType>;
  currentUser?: Maybe<UserType>;
  currentUserOrders: Array<OrderType>;
  currentUserQualifications: Array<QualificationSessionGrapheneType>;
  getFeatureFlag: Scalars['Boolean'];
  lmn?: Maybe<LetterOfMedicalNecessityType>;
  lmnDiagnoses: Array<LmnDiagnosisType>;
  lmnVerificationDetail?: Maybe<LmnVerificationDetailType>;
  merchantOnboarding?: Maybe<MerchantOnboardingConfig>;
  merchantPreQualifications: Array<QualificationSessionGrapheneType>;
  merchantQualifications?: Maybe<PaginatedMerchantQualifications>;
  order?: Maybe<OrderType>;
  patientLmns?: Maybe<Array<Maybe<LetterOfMedicalNecessityType>>>;
  pendingOrder?: Maybe<OrderType>;
  qualificationSession?: Maybe<QualificationSessionGrapheneType>;
  salesChannelByBusinessId?: Maybe<SalesChannelType>;
  salesChannelsByMerchant: Array<SalesChannelType>;
  session?: Maybe<UserSessionType>;
  shopifyAppInstallUrl?: Maybe<Scalars['String']>;
  storeOrders: PaginatedOrders;
};

export type QueryAffiliateMerchantsByOrganizationArgs = {
  affiliateOrganizationSlug: Scalars['String'];
};

export type QueryCurrentHcpLmnVerificationsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type QueryGetFeatureFlagArgs = {
  featureFlag: EnvFeature;
};

export type QueryLmnArgs = {
  id: Scalars['String'];
};

export type QueryLmnVerificationDetailArgs = {
  lmnVerificationId: Scalars['String'];
};

export type QueryMerchantOnboardingArgs = {
  merchantId: Scalars['String'];
};

export type QueryMerchantPreQualificationsArgs = {
  salesChannelId: Scalars['String'];
};

export type QueryMerchantQualificationsArgs = {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  salesChannelId: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
};

export type QueryOrderArgs = {
  businessId: Scalars['String'];
};

export type QueryPatientLmnsArgs = {
  patientId: Scalars['String'];
};

export type QueryPendingOrderArgs = {
  businessId: Scalars['String'];
};

export type QueryQualificationSessionArgs = {
  businessId: Scalars['String'];
};

export type QuerySalesChannelByBusinessIdArgs = {
  businessId: Scalars['String'];
};

export type QuerySalesChannelsByMerchantArgs = {
  merchantId: Scalars['String'];
  onlyShowVisibleToMerchant?: InputMaybe<Scalars['Boolean']>;
};

export type QuerySessionArgs = {
  sessionId: Scalars['String'];
};

export type QueryShopifyAppInstallUrlArgs = {
  shopSubdomain: Scalars['String'];
};

export type QueryStoreOrdersArgs = {
  businessId: Scalars['String'];
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  searchString?: InputMaybe<Scalars['String']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RefreshStripeStatus = {
  __typename?: 'RefreshStripeStatus';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ready?: Maybe<Scalars['Boolean']>;
  submitted?: Maybe<Scalars['Boolean']>;
};

export type RequestPasswordReset = {
  __typename?: 'RequestPasswordReset';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ResolveQualificationSessionClientSecret = {
  __typename?: 'ResolveQualificationSessionClientSecret';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
};

export type RevokeSession = {
  __typename?: 'RevokeSession';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SalesChannelType = {
  __typename?: 'SalesChannelType';
  businessId: Scalars['UUID'];
  chargeFeeFixedDollars: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  enabled: Scalars['Boolean'];
  externalSecret?: Maybe<Scalars['String']>;
  externalSubdomain?: Maybe<Scalars['String']>;
  externalUrl: Scalars['String'];
  id: Scalars['ID'];
  isVisibleToMerchant: Scalars['Boolean'];
  linkableQualificationId: Scalars['String'];
  merchant?: Maybe<MerchantType>;
  name: Scalars['String'];
  paymentFlowFormId: Scalars['String'];
  preQualFormId: Scalars['String'];
  qualificationFormId: Scalars['String'];
  qualificationUrl: Scalars['String'];
  requireSignatureForQualifications: Scalars['Boolean'];
  shopifyOnboardingComplete?: Maybe<Scalars['Boolean']>;
  shopifyShopAdminUrl?: Maybe<Scalars['String']>;
  type?: Maybe<ChannelType>;
  updatedAt: Scalars['DateTime'];
};

export type SaveCustomerPaymentMethod = {
  __typename?: 'SaveCustomerPaymentMethod';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SaveEvaluation = {
  __typename?: 'SaveEvaluation';
  evaluation?: Maybe<EvaluationType>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type SaveMerchantPaymentMethod = {
  __typename?: 'SaveMerchantPaymentMethod';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type SavePartialEvaluation = {
  __typename?: 'SavePartialEvaluation';
  ok?: Maybe<Scalars['Boolean']>;
};

export type SaveSession = {
  __typename?: 'SaveSession';
  ok?: Maybe<Scalars['Boolean']>;
};

export type ScoreEvaluation = {
  __typename?: 'ScoreEvaluation';
  redirectUrl?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
};

export type SignUp = {
  __typename?: 'SignUp';
  session?: Maybe<UserSessionType>;
  token?: Maybe<Scalars['String']>;
};

export type TryFinalizeShopifyPayment = {
  __typename?: 'TryFinalizeShopifyPayment';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  ok?: Maybe<Scalars['Boolean']>;
  redirectUrl?: Maybe<Scalars['String']>;
};

export type UpdateLetterOfMedicalNecessity = {
  __typename?: 'UpdateLetterOfMedicalNecessity';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  lmnVerification?: Maybe<LmnVerificationType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UserSessionType = {
  __typename?: 'UserSessionType';
  affiliateOrganizationMembers?: Maybe<
    Array<Maybe<AffiliateOrganizationMemberType>>
  >;
  merchantMembers?: Maybe<Array<Maybe<MerchantMemberType>>>;
  refreshToken?: Maybe<Scalars['String']>;
  salesChannels: Array<SalesChannelType>;
  user?: Maybe<UserType>;
};

export type UserType = {
  __typename?: 'UserType';
  businessId: Scalars['UUID'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isHcp?: Maybe<Scalars['Boolean']>;
  isStaff: Scalars['Boolean'];
  isSuperuser: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type VerifyLetterOfMedicalNecessity = {
  __typename?: 'VerifyLetterOfMedicalNecessity';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  lmnVerification?: Maybe<LmnVerificationType>;
  ok?: Maybe<Scalars['Boolean']>;
};

export type UserSessionFieldsFragment = {
  __typename?: 'UserSessionType';
  refreshToken?: string | null;
  user?: {
    __typename?: 'UserType';
    id: string;
    businessId: string;
    email: string;
    name?: string | null;
    isHcp?: boolean | null;
    isSuperuser: boolean;
  } | null;
  merchantMembers?: Array<{
    __typename?: 'MerchantMemberType';
    id: string;
    role?: MerchantMemberRole | null;
    merchant: {
      __typename?: 'MerchantType';
      id: string;
      name: string;
      status?: MerchantStatus | null;
      stripeOnboardingComplete?: boolean | null;
      installShopifyAppUrl?: string | null;
      featureFlags: Array<Feature>;
      category: MerchantCategory;
      affiliateOrganizationSlug?: string | null;
      affiliateOrganizationName?: string | null;
    };
  } | null> | null;
  affiliateOrganizationMembers?: Array<{
    __typename?: 'AffiliateOrganizationMemberType';
    role?: AffiliateOrganizationMemberRole | null;
    affiliateOrganization: {
      __typename?: 'AffiliateOrganizationType';
      id: string;
      name: string;
      slug: string;
    };
  } | null> | null;
  salesChannels: Array<{
    __typename?: 'SalesChannelType';
    id: string;
    name: string;
    shopifyOnboardingComplete?: boolean | null;
    shopifyShopAdminUrl?: string | null;
    type?: ChannelType | null;
    externalSubdomain?: string | null;
    externalUrl: string;
    enabled: boolean;
    businessId: string;
    isVisibleToMerchant: boolean;
  }>;
};

export type CancelOrderMutationVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type CancelOrderMutation = {
  __typename?: 'Mutation';
  cancelOrder?: {
    __typename?: 'CancelOrder';
    ok?: boolean | null;
    order?: {
      __typename?: 'OrderType';
      id: string;
      businessId: string;
      status?: OrderStatus | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type ConfirmAndChargeOrderForNormalCardMutationVariables = Exact<{
  email: Scalars['String'];
  businessId: Scalars['String'];
}>;

export type ConfirmAndChargeOrderForNormalCardMutation = {
  __typename?: 'Mutation';
  confirmAndChargeOrderForNormalCard?: {
    __typename?: 'ConfirmAndChargeOrderForNormalCard';
    ok?: boolean | null;
    order?: {
      __typename?: 'OrderType';
      id: string;
      businessId: string;
      status?: OrderStatus | null;
      useAlternativeCard?: boolean | null;
      lmnVerification?: {
        __typename?: 'LMNVerificationType';
        id: string;
      } | null;
      intakeForm?: { __typename?: 'IntakeFormType'; id: string } | null;
      evaluation?: { __typename?: 'EvaluationType'; id: string } | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type ConfirmOrderMutationVariables = Exact<{
  email: Scalars['String'];
  businessId: Scalars['String'];
  paidWith: CardType;
}>;

export type ConfirmOrderMutation = {
  __typename?: 'Mutation';
  confirmOrder?: {
    __typename?: 'ConfirmOrder';
    ok?: boolean | null;
    order?: {
      __typename?: 'OrderType';
      id: string;
      businessId: string;
      status?: OrderStatus | null;
      useAlternativeCard?: boolean | null;
      lmnVerification?: {
        __typename?: 'LMNVerificationType';
        id: string;
      } | null;
      intakeForm?: { __typename?: 'IntakeFormType'; id: string } | null;
      evaluation?: { __typename?: 'EvaluationType'; id: string } | null;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type CreateOrderFromPriceLinkMutationVariables = Exact<{
  linkablePriceId: Scalars['String'];
}>;

export type CreateOrderFromPriceLinkMutation = {
  __typename?: 'Mutation';
  createOrderFromPriceLink?: {
    __typename?: 'CreateOrderFromPriceLink';
    orderBusinessId?: string | null;
  } | null;
};

export type EnvFeatureFlagQueryVariables = Exact<{
  featureFlag: EnvFeature;
}>;

export type EnvFeatureFlagQuery = {
  __typename?: 'Query';
  getFeatureFlag: boolean;
};

export type LetterOfMedicalNecessityQueryVariables = Exact<{
  id: Scalars['String'];
}>;

export type LetterOfMedicalNecessityQuery = {
  __typename?: 'Query';
  lmn?: {
    __typename?: 'LetterOfMedicalNecessityType';
    id: string;
    url?: string | null;
  } | null;
};

export type OrderDetailsQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type OrderDetailsQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'OrderType';
    id: string;
    businessId: string;
    createdAt: string;
    paidAt?: string | null;
    paidWith?: CardType | null;
    totalAmount: any;
    isTesting: boolean;
    isSubscription: boolean;
    externalId?: string | null;
    updatedAt: string;
    status?: OrderStatus | null;
    successUrl?: string | null;
    recurring?: string | null;
    type?: string | null;
    formVariables?: string | null;
    useAlternativeCard?: boolean | null;
    cancelUrl?: string | null;
    evaluationStatus?: string | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      businessId: string;
      name?: string | null;
      email: string;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannelType';
      name: string;
      type?: ChannelType | null;
      id: string;
      businessId: string;
      paymentFlowFormId: string;
    } | null;
    orderItems: Array<{
      __typename?: 'OrderItemType';
      id: string;
      name: string;
      price: any;
      quantity: number;
      externalId?: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
    lmnVerification?: {
      __typename?: 'LMNVerificationType';
      id: string;
      status?: LmnVerificationStatus | null;
      approvalMode?: LmnApprovalMode | null;
      reviewedAt?: string | null;
      lmn?: { __typename?: 'LetterOfMedicalNecessityType'; id: string } | null;
    } | null;
    intakeForm?: {
      __typename?: 'IntakeFormType';
      id: string;
      typeformId?: string | null;
      status?: IntakeFormStatus | null;
      createdAt: string;
      score?: number | null;
    } | null;
    evaluation?: {
      __typename?: 'EvaluationType';
      id: string;
      status?: EvaluationStatus | null;
      createdAt: string;
      score: number;
    } | null;
    charge?: {
      __typename?: 'OrderChargeType';
      id: string;
      status?: OrderChargeStatus | null;
      updatedAt: string;
    } | null;
  } | null;
};

export type PendingOrderQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type PendingOrderQuery = {
  __typename?: 'Query';
  pendingOrder?: {
    __typename?: 'OrderType';
    id: string;
    businessId: string;
    createdAt: string;
    stripeClientSecret?: string | null;
    stripePaymentIntentId?: string | null;
    type?: string | null;
    isSubscription: boolean;
    recurring?: string | null;
    merchantStripeId?: string | null;
    externalExtraInformation?: any | null;
    totalAmount: any;
    externalId?: string | null;
    updatedAt: string;
    status?: OrderStatus | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannelType';
      name: string;
      id: string;
    } | null;
    orderItems: Array<{
      __typename?: 'OrderItemType';
      id: string;
      name: string;
      price: any;
      quantity: number;
      externalId?: string | null;
      createdAt: string;
      updatedAt: string;
    }>;
  } | null;
};

export type SaveEvaluationMutationVariables = Exact<{
  orderBusinessId?: InputMaybe<Scalars['String']>;
  qsBusinessId?: InputMaybe<Scalars['String']>;
  formId: Scalars['String'];
  userResponse: EvaluationFormResponse;
}>;

export type SaveEvaluationMutation = {
  __typename?: 'Mutation';
  saveEvaluation?: {
    __typename?: 'SaveEvaluation';
    evaluation?: {
      __typename?: 'EvaluationType';
      status?: EvaluationStatus | null;
      score: number;
    } | null;
  } | null;
};

export type SavePartialEvaluationMutationVariables = Exact<{
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  formId: Scalars['String'];
  userResponse: EvaluationFormResponseAnswer;
  qsBusinessId?: InputMaybe<Scalars['String']>;
  orderBusinessId?: InputMaybe<Scalars['String']>;
}>;

export type SavePartialEvaluationMutation = {
  __typename?: 'Mutation';
  savePartialEvaluation?: {
    __typename?: 'SavePartialEvaluation';
    ok?: boolean | null;
  } | null;
};

export type SaveMerchantPaymentMethodMutationVariables = Exact<{
  merchantId: Scalars['String'];
  stripePaymentMethodId: Scalars['String'];
  billingUserName: Scalars['String'];
}>;

export type SaveMerchantPaymentMethodMutation = {
  __typename?: 'Mutation';
  saveMerchantPaymentMethod?: {
    __typename?: 'SaveMerchantPaymentMethod';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type SaveCustomerPaymentMethodMutationVariables = Exact<{
  businessId: Scalars['String'];
  paymentMethodId: Scalars['String'];
}>;

export type SaveCustomerPaymentMethodMutation = {
  __typename?: 'Mutation';
  saveCustomerPaymentMethod?: {
    __typename?: 'SaveCustomerPaymentMethod';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type ScoreEvaluationMutationVariables = Exact<{
  qsBusinessId: Scalars['String'];
  formId: Scalars['String'];
  userResponse: EvaluationFormResponse;
}>;

export type ScoreEvaluationMutation = {
  __typename?: 'Mutation';
  scoreEvaluation?: {
    __typename?: 'ScoreEvaluation';
    score?: number | null;
    redirectUrl?: string | null;
  } | null;
};

export type AffiliatesByOrganizationQueryVariables = Exact<{
  affiliateOrganizationSlug: Scalars['String'];
}>;

export type AffiliatesByOrganizationQuery = {
  __typename?: 'Query';
  affiliateMerchantsByOrganization: Array<{
    __typename?: 'AffiliateMerchantResponse';
    merchantName: string;
    linkableQualId: string;
  }>;
};

export type AffiliateSignUpMutationVariables = Exact<{
  slug: Scalars['String'];
  companyName: Scalars['String'];
  phoneNumber: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
}>;

export type AffiliateSignUpMutation = {
  __typename?: 'Mutation';
  affiliateSignUp?: {
    __typename?: 'AffiliateSignUp';
    token?: string | null;
    errors?: Array<string | null> | null;
    session?: {
      __typename?: 'UserSessionType';
      refreshToken?: string | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        businessId: string;
        email: string;
        name?: string | null;
        isHcp?: boolean | null;
        isSuperuser: boolean;
      } | null;
      merchantMembers?: Array<{
        __typename?: 'MerchantMemberType';
        id: string;
        role?: MerchantMemberRole | null;
        merchant: {
          __typename?: 'MerchantType';
          id: string;
          name: string;
          status?: MerchantStatus | null;
          stripeOnboardingComplete?: boolean | null;
          installShopifyAppUrl?: string | null;
          featureFlags: Array<Feature>;
          category: MerchantCategory;
          affiliateOrganizationSlug?: string | null;
          affiliateOrganizationName?: string | null;
        };
      } | null> | null;
      affiliateOrganizationMembers?: Array<{
        __typename?: 'AffiliateOrganizationMemberType';
        role?: AffiliateOrganizationMemberRole | null;
        affiliateOrganization: {
          __typename?: 'AffiliateOrganizationType';
          id: string;
          name: string;
          slug: string;
        };
      } | null> | null;
      salesChannels: Array<{
        __typename?: 'SalesChannelType';
        id: string;
        name: string;
        shopifyOnboardingComplete?: boolean | null;
        shopifyShopAdminUrl?: string | null;
        type?: ChannelType | null;
        externalSubdomain?: string | null;
        externalUrl: string;
        enabled: boolean;
        businessId: string;
        isVisibleToMerchant: boolean;
      }>;
    } | null;
  } | null;
};

export type CheckPasswordResetMutationVariables = Exact<{
  emailB64: Scalars['String'];
  token: Scalars['String'];
}>;

export type CheckPasswordResetMutation = {
  __typename?: 'Mutation';
  checkPasswordReset?: {
    __typename?: 'CheckPasswordReset';
    ok?: boolean | null;
  } | null;
};

export type ConfirmPasswordResetMutationVariables = Exact<{
  emailB64: Scalars['String'];
  token: Scalars['String'];
  password1: Scalars['String'];
  password2: Scalars['String'];
}>;

export type ConfirmPasswordResetMutation = {
  __typename?: 'Mutation';
  confirmPasswordReset?: {
    __typename?: 'ConfirmPasswordReset';
    ok?: boolean | null;
  } | null;
};

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login?: {
    __typename?: 'Login';
    token: string;
    session?: {
      __typename?: 'UserSessionType';
      refreshToken?: string | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        businessId: string;
        email: string;
        name?: string | null;
        isHcp?: boolean | null;
        isSuperuser: boolean;
      } | null;
      merchantMembers?: Array<{
        __typename?: 'MerchantMemberType';
        id: string;
        role?: MerchantMemberRole | null;
        merchant: {
          __typename?: 'MerchantType';
          id: string;
          name: string;
          status?: MerchantStatus | null;
          stripeOnboardingComplete?: boolean | null;
          installShopifyAppUrl?: string | null;
          featureFlags: Array<Feature>;
          category: MerchantCategory;
          affiliateOrganizationSlug?: string | null;
          affiliateOrganizationName?: string | null;
        };
      } | null> | null;
      affiliateOrganizationMembers?: Array<{
        __typename?: 'AffiliateOrganizationMemberType';
        role?: AffiliateOrganizationMemberRole | null;
        affiliateOrganization: {
          __typename?: 'AffiliateOrganizationType';
          id: string;
          name: string;
          slug: string;
        };
      } | null> | null;
      salesChannels: Array<{
        __typename?: 'SalesChannelType';
        id: string;
        name: string;
        shopifyOnboardingComplete?: boolean | null;
        shopifyShopAdminUrl?: string | null;
        type?: ChannelType | null;
        externalSubdomain?: string | null;
        externalUrl: string;
        enabled: boolean;
        businessId: string;
        isVisibleToMerchant: boolean;
      }>;
    } | null;
  } | null;
};

export type MagicLinkLoginMutationVariables = Exact<{
  sesameToken: Scalars['String'];
}>;

export type MagicLinkLoginMutation = {
  __typename?: 'Mutation';
  magicLinkLogin?: {
    __typename?: 'MagicLinkLogin';
    refreshToken?: string | null;
  } | null;
};

export type RefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshTokenMutation = {
  __typename?: 'Mutation';
  refreshToken?: { __typename?: 'Refresh'; token: string } | null;
};

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestPasswordResetMutation = {
  __typename?: 'Mutation';
  requestPasswordReset?: {
    __typename?: 'RequestPasswordReset';
    ok?: boolean | null;
  } | null;
};

export type RevokeSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
}>;

export type RevokeSessionMutation = {
  __typename?: 'Mutation';
  revokeSession?: { __typename?: 'RevokeSession'; ok?: boolean | null } | null;
};

export type SaveSessionMutationVariables = Exact<{
  sessionId: Scalars['String'];
  refreshToken: Scalars['String'];
}>;

export type SaveSessionMutation = {
  __typename?: 'Mutation';
  saveSession?: { __typename?: 'SaveSession'; ok?: boolean | null } | null;
};

export type SessionQueryVariables = Exact<{
  sessionId: Scalars['String'];
}>;

export type SessionQuery = {
  __typename?: 'Query';
  session?: {
    __typename?: 'UserSessionType';
    refreshToken?: string | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      businessId: string;
      email: string;
      name?: string | null;
      isHcp?: boolean | null;
      isSuperuser: boolean;
    } | null;
    merchantMembers?: Array<{
      __typename?: 'MerchantMemberType';
      id: string;
      role?: MerchantMemberRole | null;
      merchant: {
        __typename?: 'MerchantType';
        id: string;
        name: string;
        status?: MerchantStatus | null;
        stripeOnboardingComplete?: boolean | null;
        installShopifyAppUrl?: string | null;
        featureFlags: Array<Feature>;
        category: MerchantCategory;
        affiliateOrganizationSlug?: string | null;
        affiliateOrganizationName?: string | null;
      };
    } | null> | null;
    affiliateOrganizationMembers?: Array<{
      __typename?: 'AffiliateOrganizationMemberType';
      role?: AffiliateOrganizationMemberRole | null;
      affiliateOrganization: {
        __typename?: 'AffiliateOrganizationType';
        id: string;
        name: string;
        slug: string;
      };
    } | null> | null;
    salesChannels: Array<{
      __typename?: 'SalesChannelType';
      id: string;
      name: string;
      shopifyOnboardingComplete?: boolean | null;
      shopifyShopAdminUrl?: string | null;
      type?: ChannelType | null;
      externalSubdomain?: string | null;
      externalUrl: string;
      enabled: boolean;
      businessId: string;
      isVisibleToMerchant: boolean;
    }>;
  } | null;
};

export type SignUpMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  asMerchant: Scalars['Boolean'];
  merchantName?: InputMaybe<Scalars['String']>;
  merchantType?: InputMaybe<Scalars['String']>;
}>;

export type SignUpMutation = {
  __typename?: 'Mutation';
  signUp?: {
    __typename?: 'SignUp';
    token?: string | null;
    session?: {
      __typename?: 'UserSessionType';
      refreshToken?: string | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        businessId: string;
        email: string;
        name?: string | null;
        isHcp?: boolean | null;
        isSuperuser: boolean;
      } | null;
      merchantMembers?: Array<{
        __typename?: 'MerchantMemberType';
        id: string;
        role?: MerchantMemberRole | null;
        merchant: {
          __typename?: 'MerchantType';
          id: string;
          name: string;
          status?: MerchantStatus | null;
          stripeOnboardingComplete?: boolean | null;
          installShopifyAppUrl?: string | null;
          featureFlags: Array<Feature>;
          category: MerchantCategory;
          affiliateOrganizationSlug?: string | null;
          affiliateOrganizationName?: string | null;
        };
      } | null> | null;
      affiliateOrganizationMembers?: Array<{
        __typename?: 'AffiliateOrganizationMemberType';
        role?: AffiliateOrganizationMemberRole | null;
        affiliateOrganization: {
          __typename?: 'AffiliateOrganizationType';
          id: string;
          name: string;
          slug: string;
        };
      } | null> | null;
      salesChannels: Array<{
        __typename?: 'SalesChannelType';
        id: string;
        name: string;
        shopifyOnboardingComplete?: boolean | null;
        shopifyShopAdminUrl?: string | null;
        type?: ChannelType | null;
        externalSubdomain?: string | null;
        externalUrl: string;
        enabled: boolean;
        businessId: string;
        isVisibleToMerchant: boolean;
      }>;
    } | null;
  } | null;
};

export type OrderSaleChannelDetailsQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type OrderSaleChannelDetailsQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'OrderType';
    id: string;
    isTesting: boolean;
    user?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannelType';
      id: string;
      preQualFormId: string;
      qualificationFormId: string;
      paymentFlowFormId: string;
      merchant?: {
        __typename?: 'MerchantType';
        id: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type TryFinalizeShopifyPaymentMutationVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type TryFinalizeShopifyPaymentMutation = {
  __typename?: 'Mutation';
  tryFinalizeShopifyPayment?: {
    __typename?: 'TryFinalizeShopifyPayment';
    ok?: boolean | null;
    redirectUrl?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      code?: string | null;
      message?: string | null;
    } | null> | null;
  } | null;
};

export type CurrentUserOrdersQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserOrdersQuery = {
  __typename?: 'Query';
  currentUserQualifications: Array<{
    __typename?: 'QualificationSessionGrapheneType';
    id: string;
    businessId: string;
    createdAt: string;
    status?: string | null;
    salesChannel?: {
      __typename?: 'SalesChannelType';
      name: string;
      id: string;
    } | null;
    lmnVerification?: {
      __typename?: 'LMNVerificationType';
      id: string;
      status?: LmnVerificationStatus | null;
      reviewedAt?: string | null;
      lmn?: { __typename?: 'LetterOfMedicalNecessityType'; id: string } | null;
    } | null;
  }>;
  currentUserOrders: Array<{
    __typename?: 'OrderType';
    id: string;
    businessId: string;
    createdAt: string;
    isTesting: boolean;
    totalAmount: any;
    externalId?: string | null;
    updatedAt: string;
    status?: OrderStatus | null;
    user?: {
      __typename?: 'UserType';
      id: string;
      name?: string | null;
      email: string;
    } | null;
    salesChannel?: {
      __typename?: 'SalesChannelType';
      name: string;
      id: string;
    } | null;
    lmnVerification?: {
      __typename?: 'LMNVerificationType';
      id: string;
      status?: LmnVerificationStatus | null;
      reviewedAt?: string | null;
      createdAt: string;
      updatedAt: string;
      client: { __typename?: 'UserType'; id: string };
      lmn?: { __typename?: 'LetterOfMedicalNecessityType'; id: string } | null;
    } | null;
  }>;
};

export type MerchantPreQualificationsQueryVariables = Exact<{
  salesChannelId: Scalars['String'];
}>;

export type MerchantPreQualificationsQuery = {
  __typename?: 'Query';
  merchantPreQualifications: Array<{
    __typename?: 'QualificationSessionGrapheneType';
    id: string;
    businessId: string;
    status?: string | null;
    user?: {
      __typename?: 'UserType';
      name?: string | null;
      email: string;
    } | null;
  }>;
};

export type CreateStripeLinkMutationVariables = Exact<{
  returnUrl: Scalars['String'];
  merchantId: Scalars['Int'];
}>;

export type CreateStripeLinkMutation = {
  __typename?: 'Mutation';
  createStripeLink?: {
    __typename?: 'CreateStripeLink';
    url?: string | null;
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type RefreshStripeStatusMutationVariables = Exact<{
  merchantId: Scalars['Int'];
}>;

export type RefreshStripeStatusMutation = {
  __typename?: 'Mutation';
  refreshStripeStatus?: {
    __typename?: 'RefreshStripeStatus';
    ready?: boolean | null;
    submitted?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type StoreOrdersQueryVariables = Exact<{
  businessId: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type StoreOrdersQuery = {
  __typename?: 'Query';
  storeOrders: {
    __typename?: 'PaginatedOrders';
    currentPage: number;
    pageSize: number;
    count: number;
    orders: Array<{
      __typename?: 'BasicOrderType';
      id: string;
      businessId: string;
      createdAt: string;
      isTesting: boolean;
      recurring?: string | null;
      totalAmount: any;
      externalId?: string | null;
      updatedAt: string;
      status?: OrderStatus | null;
      paidWith?: CardType | null;
      user?: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        email: string;
      } | null;
      salesChannel?: {
        __typename?: 'SalesChannelType';
        name: string;
        id: string;
      } | null;
    }>;
  };
};

export type PromoDetailsByBusinessIdQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type PromoDetailsByBusinessIdQuery = {
  __typename?: 'Query';
  salesChannelByBusinessId?: {
    __typename?: 'SalesChannelType';
    externalSecret?: string | null;
    linkableQualificationId: string;
  } | null;
};

export type MerchantOnboardingQueryVariables = Exact<{
  merchantId: Scalars['String'];
}>;

export type MerchantOnboardingQuery = {
  __typename?: 'Query';
  merchantOnboarding?: {
    __typename?: 'MerchantOnboardingConfig';
    showQualifications?: boolean | null;
    needsCcEntry?: boolean | null;
    merchantEmail: string;
    onboardingType: MerchantOnboardingType;
    invoicingEnabled?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type MerchantQualificationsQueryVariables = Exact<{
  salesChannelId: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;

export type MerchantQualificationsQuery = {
  __typename?: 'Query';
  merchantQualifications?: {
    __typename?: 'PaginatedMerchantQualifications';
    currentPage: number;
    pageSize: number;
    count: number;
    qualifications: Array<{
      __typename?: 'QualificationSessionGrapheneType';
      id: string;
      businessId: string;
      createdAt: string;
      lmnReviewedAt?: string | null;
      utm?: string | null;
      externalUserId?: string | null;
      status?: string | null;
      customerStatus?: CustomerStatus | null;
      user?: {
        __typename?: 'UserType';
        name?: string | null;
        email: string;
      } | null;
    }>;
  } | null;
};

export type EndSubscriptionMutationVariables = Exact<{
  orderBusinessId: Scalars['String'];
}>;

export type EndSubscriptionMutation = {
  __typename?: 'Mutation';
  endSubscription?: {
    __typename?: 'EndSubscription';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type CreatePreQualificationSessionFromLinkMutationVariables = Exact<{
  linkableQualId: Scalars['String'];
  utm?: InputMaybe<Scalars['String']>;
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  successAction?: InputMaybe<QualificationSuccessActionType>;
}>;

export type CreatePreQualificationSessionFromLinkMutation = {
  __typename?: 'Mutation';
  createQualificationSessionFromLink?: {
    __typename?: 'CreateQualificationSessionFromLink';
    qsBusinessId: string;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type PreQualificationSessionDetailsQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type PreQualificationSessionDetailsQuery = {
  __typename?: 'Query';
  qualificationSession?: {
    __typename?: 'QualificationSessionGrapheneType';
    formId: string;
    merchantName?: string | null;
    variables?: string | null;
    status?: string | null;
    retryUrl?: string | null;
    salesChannelType: ChannelType;
    successRedirectUrl?: string | null;
    failureRedirectUrl?: string | null;
    successAction?: QualificationSuccessActionType | null;
  } | null;
};

export type AssignHcpToLmnVerificationMutationVariables = Exact<{
  lmnVerificationId: Scalars['String'];
}>;

export type AssignHcpToLmnVerificationMutation = {
  __typename?: 'Mutation';
  assignHcpToLmnVerification?: {
    __typename?: 'LmnVerificationAssignment';
    lmnVerification?: { __typename?: 'LMNVerificationType'; id: string } | null;
  } | null;
};

export type CreateLetterOfMedicalNecessityMutationVariables = Exact<{
  issuedDate: Scalars['Date'];
  expiredDate: Scalars['Date'];
  patientId: Scalars['Int'];
  merchantId: Scalars['Int'];
  lmnDiagnosisId?: InputMaybe<Scalars['Int']>;
  lmnFile?: InputMaybe<Scalars['Upload']>;
}>;

export type CreateLetterOfMedicalNecessityMutation = {
  __typename?: 'Mutation';
  createLetterOfMedicalNecessity?: {
    __typename?: 'CreateLetterOfMedicalNecessity';
    ok?: boolean | null;
    lmnId?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
      messages?: Array<string | null> | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type CurrentHcpLmnVerificationsQueryVariables = Exact<{
  currentPage: Scalars['Int'];
  pageSize: Scalars['Int'];
  searchString?: InputMaybe<Scalars['String']>;
}>;

export type CurrentHcpLmnVerificationsQuery = {
  __typename?: 'Query';
  currentHcpLmnVerifications?: {
    __typename?: 'PaginatedLmnVerificationsType';
    currentPage: number;
    pageSize: number;
    count: number;
    verifications?: Array<{
      __typename?: 'LMNVerificationType';
      id: string;
      status?: LmnVerificationStatus | null;
      reviewedAt?: string | null;
      merchantName?: string | null;
      client: { __typename?: 'UserType'; name?: string | null; id: string };
      order?: {
        __typename?: 'BasicOrderType';
        id: string;
        businessId: string;
        externalId?: string | null;
        salesChannel?: {
          __typename?: 'SalesChannelType';
          id: string;
          name: string;
        } | null;
      } | null;
      verifiedBy?: {
        __typename?: 'HealthCareProviderType';
        id: string;
        name: string;
        user: { __typename?: 'UserType'; id: string; name?: string | null };
      } | null;
    }> | null;
  } | null;
};

export type LmnVerificationDetailQueryVariables = Exact<{
  lmnVerificationId: Scalars['String'];
}>;

export type LmnVerificationDetailQuery = {
  __typename?: 'Query';
  lmnVerificationDetail?: {
    __typename?: 'LmnVerificationDetailType';
    merchantName: string;
    merchantId: string;
    merchantHasRecommendedProducts: boolean;
    previousUnclaimedRecordId?: string | null;
    nextUnclaimedRecordId?: string | null;
    lmnVerification?: {
      __typename?: 'LMNVerificationType';
      id: string;
      status?: LmnVerificationStatus | null;
      reviewedAt?: string | null;
      expiredAt?: string | null;
      client: {
        __typename?: 'UserType';
        id: string;
        name?: string | null;
        email: string;
      };
      order?: {
        __typename?: 'BasicOrderType';
        id: string;
        orderItems: Array<{
          __typename?: 'OrderItemType';
          id: string;
          name: string;
          quantity: number;
          externalId?: string | null;
        }>;
      } | null;
      verifiedBy?: {
        __typename?: 'HealthCareProviderType';
        id: string;
        name: string;
        user: { __typename?: 'UserType'; id: string };
      } | null;
      lmn?: { __typename?: 'LetterOfMedicalNecessityType'; id: string } | null;
    } | null;
    evaluationFormData?: {
      __typename?: 'EvaluationFormDataType';
      formId?: string | null;
      answers?: Array<{
        __typename?: 'EvaluationFormDataAnswerType';
        questionId?: string | null;
        values?: Array<string | null> | null;
      } | null> | null;
    } | null;
    intakeFormData?: Array<{
      __typename?: 'IntakeFormDataType';
      content?: string | null;
      answers?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type LmnUnclaimedQueryVariables = Exact<{
  lmnVerificationId: Scalars['String'];
}>;

export type LmnUnclaimedQuery = {
  __typename?: 'Query';
  lmnVerificationDetail?: {
    __typename?: 'LmnVerificationDetailType';
    previousUnclaimedRecordId?: string | null;
    nextUnclaimedRecordId?: string | null;
  } | null;
};

export type PatientLmnsQueryVariables = Exact<{
  patientId: Scalars['String'];
}>;

export type PatientLmnsQuery = {
  __typename?: 'Query';
  patientLmns?: Array<{
    __typename?: 'LetterOfMedicalNecessityType';
    id: string;
    url?: string | null;
    issuedDate: string;
    expiredDate: string;
    issuedBy?: {
      __typename?: 'HealthCareProviderType';
      id: string;
      name: string;
    } | null;
  } | null> | null;
};

export type UpdateLetterOfMedicalNecessityMutationVariables = Exact<{
  lmnVerificationId: Scalars['Int'];
  lmnId: Scalars['Int'];
  comment?: InputMaybe<Scalars['String']>;
}>;

export type UpdateLetterOfMedicalNecessityMutation = {
  __typename?: 'Mutation';
  updateLetterOfMedicalNecessity?: {
    __typename?: 'UpdateLetterOfMedicalNecessity';
    ok?: boolean | null;
    lmnVerification?: {
      __typename?: 'LMNVerificationType';
      status?: LmnVerificationStatus | null;
      id: string;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
      messages?: Array<string | null> | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type VerifyLetterOfMedicalNecessityMutationVariables = Exact<{
  expiredAt: Scalars['Date'];
  lmnId?: InputMaybe<Scalars['Int']>;
  verificationId: Scalars['Int'];
  status: LmnVerificationStatus;
}>;

export type VerifyLetterOfMedicalNecessityMutation = {
  __typename?: 'Mutation';
  verifyLetterOfMedicalNecessity?: {
    __typename?: 'VerifyLetterOfMedicalNecessity';
    ok?: boolean | null;
    lmnVerification?: {
      __typename?: 'LMNVerificationType';
      status?: LmnVerificationStatus | null;
      id: string;
    } | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      message?: string | null;
      messages?: Array<string | null> | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type LmnDiagnosisQueryQueryVariables = Exact<{ [key: string]: never }>;

export type LmnDiagnosisQueryQuery = {
  __typename?: 'Query';
  lmnDiagnoses: Array<{
    __typename?: 'LMNDiagnosisType';
    diagnosisId: number;
    name: string;
  }>;
};

export type CreateQualificationSessionFromAffiliateMutationVariables = Exact<{
  affiliateOrgSlug: Scalars['String'];
  salesChannelQualId: Scalars['String'];
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
}>;

export type CreateQualificationSessionFromAffiliateMutation = {
  __typename?: 'Mutation';
  createQualificationSessionFromAffiliate?: {
    __typename?: 'CreateQualificationSessionFromAffiliate';
    qsBusinessId: string;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type CreateQualificationSessionFromLinkMutationVariables = Exact<{
  linkableQualId: Scalars['String'];
  persistentBrowserId?: InputMaybe<Scalars['String']>;
  externalUserId?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
  utm?: InputMaybe<Scalars['String']>;
}>;

export type CreateQualificationSessionFromLinkMutation = {
  __typename?: 'Mutation';
  createQualificationSessionFromLink?: {
    __typename?: 'CreateQualificationSessionFromLink';
    qsBusinessId: string;
    errors?: Array<{
      __typename?: 'ErrorType';
      messages?: Array<string | null> | null;
    } | null> | null;
  } | null;
};

export type ConfirmQualificationSessionPaymentMutationVariables = Exact<{
  qsBusinessId: Scalars['String'];
}>;

export type ConfirmQualificationSessionPaymentMutation = {
  __typename?: 'Mutation';
  confirmQualificationSessionPayment?: {
    __typename?: 'ConfirmQualificationSessionPayment';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type ResolveQualificationClientSecretMutationVariables = Exact<{
  qsBusinessId: Scalars['String'];
}>;

export type ResolveQualificationClientSecretMutation = {
  __typename?: 'Mutation';
  resolveQualificationClientSecret?: {
    __typename?: 'ResolveQualificationSessionClientSecret';
    ok?: boolean | null;
    stripeClientSecret?: string | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      field?: string | null;
      messages?: Array<string | null> | null;
      message?: string | null;
      code?: string | null;
    } | null> | null;
  } | null;
};

export type QualificationSessionDetailsQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type QualificationSessionDetailsQuery = {
  __typename?: 'Query';
  qualificationSession?: {
    __typename?: 'QualificationSessionGrapheneType';
    formId: string;
    merchantName?: string | null;
    variables?: string | null;
    sessionType?: QualificationSessionType | null;
    retryUrl?: string | null;
    businessId: string;
    status?: string | null;
    successRedirectUrl?: string | null;
    failureRedirectUrl?: string | null;
    fee?: any | null;
    successAction?: QualificationSuccessActionType | null;
    customerStatus?: CustomerStatus | null;
    user?: {
      __typename?: 'UserType';
      email: string;
      name?: string | null;
    } | null;
  } | null;
};

export type ConnectSalesChannelToMerchantMutationVariables = Exact<{
  salesChannelBusinessId: Scalars['String'];
  merchantId: Scalars['Int'];
}>;

export type ConnectSalesChannelToMerchantMutation = {
  __typename?: 'Mutation';
  connectSalesChannelToMerchant?: {
    __typename?: 'ConnectSalesChannelToMerchant';
    ok?: boolean | null;
    errors?: Array<{
      __typename?: 'ErrorType';
      message?: string | null;
    } | null> | null;
    salesChannel?: {
      __typename?: 'SalesChannelType';
      id: string;
      shopifyShopAdminUrl?: string | null;
      merchant?: {
        __typename?: 'MerchantType';
        id: string;
        name: string;
      } | null;
    } | null;
  } | null;
};

export type SalesChannelsByMerchantQueryVariables = Exact<{
  merchantId: Scalars['String'];
  onlyShowVisibleToMerchant: Scalars['Boolean'];
}>;

export type SalesChannelsByMerchantQuery = {
  __typename?: 'Query';
  salesChannelsByMerchant: Array<{
    __typename?: 'SalesChannelType';
    id: string;
    name: string;
    enabled: boolean;
    isVisibleToMerchant: boolean;
    qualificationUrl: string;
    type?: ChannelType | null;
    businessId: string;
  }>;
};

export type ShopifyAppInstallUrlQueryVariables = Exact<{
  shopSubdomain: Scalars['String'];
}>;

export type ShopifyAppInstallUrlQuery = {
  __typename?: 'Query';
  shopifyAppInstallUrl?: string | null;
};

export type SalesChannelByBusinessIdQueryVariables = Exact<{
  businessId: Scalars['String'];
}>;

export type SalesChannelByBusinessIdQuery = {
  __typename?: 'Query';
  salesChannelByBusinessId?: {
    __typename?: 'SalesChannelType';
    id: string;
    name: string;
    enabled: boolean;
    externalUrl: string;
    merchant?: { __typename?: 'MerchantType'; id: string } | null;
  } | null;
};

export const UserSessionFieldsFragmentDoc = gql`
  fragment UserSessionFields on UserSessionType {
    refreshToken
    user {
      id
      businessId
      email
      name
      isHcp
      isSuperuser
    }
    merchantMembers {
      id
      role
      merchant {
        id
        name
        status
        stripeOnboardingComplete
        installShopifyAppUrl
        featureFlags
        category
        affiliateOrganizationSlug
        affiliateOrganizationName
      }
    }
    affiliateOrganizationMembers {
      affiliateOrganization {
        id
        name
        slug
      }
      role
    }
    salesChannels {
      id
      name
      shopifyOnboardingComplete
      shopifyShopAdminUrl
      type
      externalSubdomain
      externalUrl
      enabled
      businessId
      isVisibleToMerchant
    }
  }
`;
export const CancelOrderDocument = gql`
  mutation CancelOrder($businessId: String!) {
    cancelOrder(businessId: $businessId) {
      ok
      order {
        id
        businessId
        status
      }
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type CancelOrderMutationFn = Apollo.MutationFunction<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
export function useCancelOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CancelOrderMutation,
    CancelOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CancelOrderMutation, CancelOrderMutationVariables>(
    CancelOrderDocument,
    options
  );
}
export type CancelOrderMutationHookResult = ReturnType<
  typeof useCancelOrderMutation
>;
export type CancelOrderMutationResult =
  Apollo.MutationResult<CancelOrderMutation>;
export type CancelOrderMutationOptions = Apollo.BaseMutationOptions<
  CancelOrderMutation,
  CancelOrderMutationVariables
>;
export const ConfirmAndChargeOrderForNormalCardDocument = gql`
  mutation confirmAndChargeOrderForNormalCard(
    $email: String!
    $businessId: String!
  ) {
    confirmAndChargeOrderForNormalCard(email: $email, businessId: $businessId) {
      ok
      order {
        id
        businessId
        status
        lmnVerification {
          id
        }
        intakeForm {
          id
        }
        evaluation {
          id
        }
        useAlternativeCard
      }
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type ConfirmAndChargeOrderForNormalCardMutationFn =
  Apollo.MutationFunction<
    ConfirmAndChargeOrderForNormalCardMutation,
    ConfirmAndChargeOrderForNormalCardMutationVariables
  >;
export function useConfirmAndChargeOrderForNormalCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmAndChargeOrderForNormalCardMutation,
    ConfirmAndChargeOrderForNormalCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmAndChargeOrderForNormalCardMutation,
    ConfirmAndChargeOrderForNormalCardMutationVariables
  >(ConfirmAndChargeOrderForNormalCardDocument, options);
}
export type ConfirmAndChargeOrderForNormalCardMutationHookResult = ReturnType<
  typeof useConfirmAndChargeOrderForNormalCardMutation
>;
export type ConfirmAndChargeOrderForNormalCardMutationResult =
  Apollo.MutationResult<ConfirmAndChargeOrderForNormalCardMutation>;
export type ConfirmAndChargeOrderForNormalCardMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmAndChargeOrderForNormalCardMutation,
    ConfirmAndChargeOrderForNormalCardMutationVariables
  >;
export const ConfirmOrderDocument = gql`
  mutation ConfirmOrder(
    $email: String!
    $businessId: String!
    $paidWith: CardType!
  ) {
    confirmOrder(email: $email, businessId: $businessId, paidWith: $paidWith) {
      ok
      order {
        id
        businessId
        status
        lmnVerification {
          id
        }
        intakeForm {
          id
        }
        evaluation {
          id
        }
        useAlternativeCard
      }
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type ConfirmOrderMutationFn = Apollo.MutationFunction<
  ConfirmOrderMutation,
  ConfirmOrderMutationVariables
>;
export function useConfirmOrderMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmOrderMutation,
    ConfirmOrderMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmOrderMutation,
    ConfirmOrderMutationVariables
  >(ConfirmOrderDocument, options);
}
export type ConfirmOrderMutationHookResult = ReturnType<
  typeof useConfirmOrderMutation
>;
export type ConfirmOrderMutationResult =
  Apollo.MutationResult<ConfirmOrderMutation>;
export type ConfirmOrderMutationOptions = Apollo.BaseMutationOptions<
  ConfirmOrderMutation,
  ConfirmOrderMutationVariables
>;
export const CreateOrderFromPriceLinkDocument = gql`
  mutation CreateOrderFromPriceLink($linkablePriceId: String!) {
    createOrderFromPriceLink(linkablePriceId: $linkablePriceId) {
      orderBusinessId
    }
  }
`;
export type CreateOrderFromPriceLinkMutationFn = Apollo.MutationFunction<
  CreateOrderFromPriceLinkMutation,
  CreateOrderFromPriceLinkMutationVariables
>;
export function useCreateOrderFromPriceLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOrderFromPriceLinkMutation,
    CreateOrderFromPriceLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOrderFromPriceLinkMutation,
    CreateOrderFromPriceLinkMutationVariables
  >(CreateOrderFromPriceLinkDocument, options);
}
export type CreateOrderFromPriceLinkMutationHookResult = ReturnType<
  typeof useCreateOrderFromPriceLinkMutation
>;
export type CreateOrderFromPriceLinkMutationResult =
  Apollo.MutationResult<CreateOrderFromPriceLinkMutation>;
export type CreateOrderFromPriceLinkMutationOptions =
  Apollo.BaseMutationOptions<
    CreateOrderFromPriceLinkMutation,
    CreateOrderFromPriceLinkMutationVariables
  >;
export const EnvFeatureFlagDocument = gql`
  query EnvFeatureFlag($featureFlag: EnvFeature!) {
    getFeatureFlag(featureFlag: $featureFlag)
  }
`;
export function useEnvFeatureFlagQuery(
  baseOptions: Apollo.QueryHookOptions<
    EnvFeatureFlagQuery,
    EnvFeatureFlagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<EnvFeatureFlagQuery, EnvFeatureFlagQueryVariables>(
    EnvFeatureFlagDocument,
    options
  );
}
export function useEnvFeatureFlagLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    EnvFeatureFlagQuery,
    EnvFeatureFlagQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<EnvFeatureFlagQuery, EnvFeatureFlagQueryVariables>(
    EnvFeatureFlagDocument,
    options
  );
}
export type EnvFeatureFlagQueryHookResult = ReturnType<
  typeof useEnvFeatureFlagQuery
>;
export type EnvFeatureFlagLazyQueryHookResult = ReturnType<
  typeof useEnvFeatureFlagLazyQuery
>;
export type EnvFeatureFlagQueryResult = Apollo.QueryResult<
  EnvFeatureFlagQuery,
  EnvFeatureFlagQueryVariables
>;
export const LetterOfMedicalNecessityDocument = gql`
  query LetterOfMedicalNecessity($id: String!) {
    lmn(id: $id) {
      id
      url
    }
  }
`;
export function useLetterOfMedicalNecessityQuery(
  baseOptions: Apollo.QueryHookOptions<
    LetterOfMedicalNecessityQuery,
    LetterOfMedicalNecessityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LetterOfMedicalNecessityQuery,
    LetterOfMedicalNecessityQueryVariables
  >(LetterOfMedicalNecessityDocument, options);
}
export function useLetterOfMedicalNecessityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LetterOfMedicalNecessityQuery,
    LetterOfMedicalNecessityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LetterOfMedicalNecessityQuery,
    LetterOfMedicalNecessityQueryVariables
  >(LetterOfMedicalNecessityDocument, options);
}
export type LetterOfMedicalNecessityQueryHookResult = ReturnType<
  typeof useLetterOfMedicalNecessityQuery
>;
export type LetterOfMedicalNecessityLazyQueryHookResult = ReturnType<
  typeof useLetterOfMedicalNecessityLazyQuery
>;
export type LetterOfMedicalNecessityQueryResult = Apollo.QueryResult<
  LetterOfMedicalNecessityQuery,
  LetterOfMedicalNecessityQueryVariables
>;
export const OrderDetailsDocument = gql`
  query OrderDetails($businessId: String!) {
    order(businessId: $businessId) {
      id
      businessId
      createdAt
      paidAt
      paidWith
      user {
        id
        businessId
        name
        email
      }
      totalAmount
      isTesting
      isSubscription
      externalId
      createdAt
      updatedAt
      status
      successUrl
      recurring
      type
      formVariables
      salesChannel {
        name
        type
        id
        businessId
        paymentFlowFormId
      }
      orderItems {
        id
        name
        price
        quantity
        externalId
        createdAt
        updatedAt
      }
      lmnVerification {
        id
        status
        approvalMode
        reviewedAt
        lmn {
          id
        }
      }
      intakeForm {
        id
        typeformId
        status
        createdAt
        score
      }
      evaluation {
        id
        status
        createdAt
        score
      }
      charge {
        id
        status
        updatedAt
      }
      useAlternativeCard
      successUrl
      cancelUrl
      evaluationStatus
    }
  }
`;
export function useOrderDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(
    OrderDetailsDocument,
    options
  );
}
export function useOrderDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderDetailsQuery,
    OrderDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<OrderDetailsQuery, OrderDetailsQueryVariables>(
    OrderDetailsDocument,
    options
  );
}
export type OrderDetailsQueryHookResult = ReturnType<
  typeof useOrderDetailsQuery
>;
export type OrderDetailsLazyQueryHookResult = ReturnType<
  typeof useOrderDetailsLazyQuery
>;
export type OrderDetailsQueryResult = Apollo.QueryResult<
  OrderDetailsQuery,
  OrderDetailsQueryVariables
>;
export const PendingOrderDocument = gql`
  query PendingOrder($businessId: String!) {
    pendingOrder(businessId: $businessId) {
      id
      businessId
      createdAt
      stripeClientSecret
      stripePaymentIntentId
      type
      isSubscription
      recurring
      merchantStripeId
      user {
        id
        name
        email
      }
      externalExtraInformation
      totalAmount
      externalId
      createdAt
      updatedAt
      status
      salesChannel {
        name
        id
      }
      orderItems {
        id
        name
        price
        quantity
        externalId
        createdAt
        updatedAt
      }
    }
  }
`;
export function usePendingOrderQuery(
  baseOptions: Apollo.QueryHookOptions<
    PendingOrderQuery,
    PendingOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PendingOrderQuery, PendingOrderQueryVariables>(
    PendingOrderDocument,
    options
  );
}
export function usePendingOrderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PendingOrderQuery,
    PendingOrderQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PendingOrderQuery, PendingOrderQueryVariables>(
    PendingOrderDocument,
    options
  );
}
export type PendingOrderQueryHookResult = ReturnType<
  typeof usePendingOrderQuery
>;
export type PendingOrderLazyQueryHookResult = ReturnType<
  typeof usePendingOrderLazyQuery
>;
export type PendingOrderQueryResult = Apollo.QueryResult<
  PendingOrderQuery,
  PendingOrderQueryVariables
>;
export const SaveEvaluationDocument = gql`
  mutation saveEvaluation(
    $orderBusinessId: String
    $qsBusinessId: String
    $formId: String!
    $userResponse: EvaluationFormResponse!
  ) {
    saveEvaluation(
      orderBusinessId: $orderBusinessId
      qsBusinessId: $qsBusinessId
      formId: $formId
      userResponse: $userResponse
    ) {
      evaluation {
        status
        score
      }
    }
  }
`;
export type SaveEvaluationMutationFn = Apollo.MutationFunction<
  SaveEvaluationMutation,
  SaveEvaluationMutationVariables
>;
export function useSaveEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveEvaluationMutation,
    SaveEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveEvaluationMutation,
    SaveEvaluationMutationVariables
  >(SaveEvaluationDocument, options);
}
export type SaveEvaluationMutationHookResult = ReturnType<
  typeof useSaveEvaluationMutation
>;
export type SaveEvaluationMutationResult =
  Apollo.MutationResult<SaveEvaluationMutation>;
export type SaveEvaluationMutationOptions = Apollo.BaseMutationOptions<
  SaveEvaluationMutation,
  SaveEvaluationMutationVariables
>;
export const SavePartialEvaluationDocument = gql`
  mutation savePartialEvaluation(
    $persistentBrowserId: String
    $formId: String!
    $userResponse: EvaluationFormResponseAnswer!
    $qsBusinessId: String
    $orderBusinessId: String
  ) {
    savePartialEvaluation(
      persistentBrowserId: $persistentBrowserId
      formId: $formId
      userResponse: $userResponse
      qsBusinessId: $qsBusinessId
      orderBusinessId: $orderBusinessId
    ) {
      ok
    }
  }
`;
export type SavePartialEvaluationMutationFn = Apollo.MutationFunction<
  SavePartialEvaluationMutation,
  SavePartialEvaluationMutationVariables
>;
export function useSavePartialEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SavePartialEvaluationMutation,
    SavePartialEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SavePartialEvaluationMutation,
    SavePartialEvaluationMutationVariables
  >(SavePartialEvaluationDocument, options);
}
export type SavePartialEvaluationMutationHookResult = ReturnType<
  typeof useSavePartialEvaluationMutation
>;
export type SavePartialEvaluationMutationResult =
  Apollo.MutationResult<SavePartialEvaluationMutation>;
export type SavePartialEvaluationMutationOptions = Apollo.BaseMutationOptions<
  SavePartialEvaluationMutation,
  SavePartialEvaluationMutationVariables
>;
export const SaveMerchantPaymentMethodDocument = gql`
  mutation saveMerchantPaymentMethod(
    $merchantId: String!
    $stripePaymentMethodId: String!
    $billingUserName: String!
  ) {
    saveMerchantPaymentMethod(
      merchantId: $merchantId
      stripePaymentMethodId: $stripePaymentMethodId
      billingUserName: $billingUserName
    ) {
      ok
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type SaveMerchantPaymentMethodMutationFn = Apollo.MutationFunction<
  SaveMerchantPaymentMethodMutation,
  SaveMerchantPaymentMethodMutationVariables
>;
export function useSaveMerchantPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveMerchantPaymentMethodMutation,
    SaveMerchantPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveMerchantPaymentMethodMutation,
    SaveMerchantPaymentMethodMutationVariables
  >(SaveMerchantPaymentMethodDocument, options);
}
export type SaveMerchantPaymentMethodMutationHookResult = ReturnType<
  typeof useSaveMerchantPaymentMethodMutation
>;
export type SaveMerchantPaymentMethodMutationResult =
  Apollo.MutationResult<SaveMerchantPaymentMethodMutation>;
export type SaveMerchantPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    SaveMerchantPaymentMethodMutation,
    SaveMerchantPaymentMethodMutationVariables
  >;
export const SaveCustomerPaymentMethodDocument = gql`
  mutation saveCustomerPaymentMethod(
    $businessId: String!
    $paymentMethodId: String!
  ) {
    saveCustomerPaymentMethod(
      businessId: $businessId
      paymentMethodId: $paymentMethodId
    ) {
      ok
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type SaveCustomerPaymentMethodMutationFn = Apollo.MutationFunction<
  SaveCustomerPaymentMethodMutation,
  SaveCustomerPaymentMethodMutationVariables
>;
export function useSaveCustomerPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveCustomerPaymentMethodMutation,
    SaveCustomerPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SaveCustomerPaymentMethodMutation,
    SaveCustomerPaymentMethodMutationVariables
  >(SaveCustomerPaymentMethodDocument, options);
}
export type SaveCustomerPaymentMethodMutationHookResult = ReturnType<
  typeof useSaveCustomerPaymentMethodMutation
>;
export type SaveCustomerPaymentMethodMutationResult =
  Apollo.MutationResult<SaveCustomerPaymentMethodMutation>;
export type SaveCustomerPaymentMethodMutationOptions =
  Apollo.BaseMutationOptions<
    SaveCustomerPaymentMethodMutation,
    SaveCustomerPaymentMethodMutationVariables
  >;
export const ScoreEvaluationDocument = gql`
  mutation scoreEvaluation(
    $qsBusinessId: String!
    $formId: String!
    $userResponse: EvaluationFormResponse!
  ) {
    scoreEvaluation(
      qsBusinessId: $qsBusinessId
      formId: $formId
      userResponse: $userResponse
    ) {
      score
      redirectUrl
    }
  }
`;
export type ScoreEvaluationMutationFn = Apollo.MutationFunction<
  ScoreEvaluationMutation,
  ScoreEvaluationMutationVariables
>;
export function useScoreEvaluationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ScoreEvaluationMutation,
    ScoreEvaluationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ScoreEvaluationMutation,
    ScoreEvaluationMutationVariables
  >(ScoreEvaluationDocument, options);
}
export type ScoreEvaluationMutationHookResult = ReturnType<
  typeof useScoreEvaluationMutation
>;
export type ScoreEvaluationMutationResult =
  Apollo.MutationResult<ScoreEvaluationMutation>;
export type ScoreEvaluationMutationOptions = Apollo.BaseMutationOptions<
  ScoreEvaluationMutation,
  ScoreEvaluationMutationVariables
>;
export const AffiliatesByOrganizationDocument = gql`
  query AffiliatesByOrganization($affiliateOrganizationSlug: String!) {
    affiliateMerchantsByOrganization(
      affiliateOrganizationSlug: $affiliateOrganizationSlug
    ) {
      merchantName
      linkableQualId
    }
  }
`;
export function useAffiliatesByOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<
    AffiliatesByOrganizationQuery,
    AffiliatesByOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    AffiliatesByOrganizationQuery,
    AffiliatesByOrganizationQueryVariables
  >(AffiliatesByOrganizationDocument, options);
}
export function useAffiliatesByOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AffiliatesByOrganizationQuery,
    AffiliatesByOrganizationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    AffiliatesByOrganizationQuery,
    AffiliatesByOrganizationQueryVariables
  >(AffiliatesByOrganizationDocument, options);
}
export type AffiliatesByOrganizationQueryHookResult = ReturnType<
  typeof useAffiliatesByOrganizationQuery
>;
export type AffiliatesByOrganizationLazyQueryHookResult = ReturnType<
  typeof useAffiliatesByOrganizationLazyQuery
>;
export type AffiliatesByOrganizationQueryResult = Apollo.QueryResult<
  AffiliatesByOrganizationQuery,
  AffiliatesByOrganizationQueryVariables
>;
export const AffiliateSignUpDocument = gql`
  mutation AffiliateSignUp(
    $slug: String!
    $companyName: String!
    $phoneNumber: String!
    $email: String!
    $name: String!
    $password: String!
  ) {
    affiliateSignUp(
      slug: $slug
      companyName: $companyName
      phoneNumber: $phoneNumber
      email: $email
      name: $name
      password: $password
    ) {
      token
      session {
        ...UserSessionFields
      }
      errors
    }
  }
  ${UserSessionFieldsFragmentDoc}
`;
export type AffiliateSignUpMutationFn = Apollo.MutationFunction<
  AffiliateSignUpMutation,
  AffiliateSignUpMutationVariables
>;
export function useAffiliateSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AffiliateSignUpMutation,
    AffiliateSignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AffiliateSignUpMutation,
    AffiliateSignUpMutationVariables
  >(AffiliateSignUpDocument, options);
}
export type AffiliateSignUpMutationHookResult = ReturnType<
  typeof useAffiliateSignUpMutation
>;
export type AffiliateSignUpMutationResult =
  Apollo.MutationResult<AffiliateSignUpMutation>;
export type AffiliateSignUpMutationOptions = Apollo.BaseMutationOptions<
  AffiliateSignUpMutation,
  AffiliateSignUpMutationVariables
>;
export const CheckPasswordResetDocument = gql`
  mutation CheckPasswordReset($emailB64: String!, $token: String!) {
    checkPasswordReset(emailB64: $emailB64, token: $token) {
      ok
    }
  }
`;
export type CheckPasswordResetMutationFn = Apollo.MutationFunction<
  CheckPasswordResetMutation,
  CheckPasswordResetMutationVariables
>;
export function useCheckPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CheckPasswordResetMutation,
    CheckPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CheckPasswordResetMutation,
    CheckPasswordResetMutationVariables
  >(CheckPasswordResetDocument, options);
}
export type CheckPasswordResetMutationHookResult = ReturnType<
  typeof useCheckPasswordResetMutation
>;
export type CheckPasswordResetMutationResult =
  Apollo.MutationResult<CheckPasswordResetMutation>;
export type CheckPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  CheckPasswordResetMutation,
  CheckPasswordResetMutationVariables
>;
export const ConfirmPasswordResetDocument = gql`
  mutation ConfirmPasswordReset(
    $emailB64: String!
    $token: String!
    $password1: String!
    $password2: String!
  ) {
    confirmPasswordReset(
      emailB64: $emailB64
      token: $token
      password1: $password1
      password2: $password2
    ) {
      ok
    }
  }
`;
export type ConfirmPasswordResetMutationFn = Apollo.MutationFunction<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;
export function useConfirmPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmPasswordResetMutation,
    ConfirmPasswordResetMutationVariables
  >(ConfirmPasswordResetDocument, options);
}
export type ConfirmPasswordResetMutationHookResult = ReturnType<
  typeof useConfirmPasswordResetMutation
>;
export type ConfirmPasswordResetMutationResult =
  Apollo.MutationResult<ConfirmPasswordResetMutation>;
export type ConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPasswordResetMutation,
  ConfirmPasswordResetMutationVariables
>;
export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      session {
        ...UserSessionFields
      }
    }
  }
  ${UserSessionFieldsFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    options
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const MagicLinkLoginDocument = gql`
  mutation MagicLinkLogin($sesameToken: String!) {
    magicLinkLogin(sesameToken: $sesameToken) {
      refreshToken
    }
  }
`;
export type MagicLinkLoginMutationFn = Apollo.MutationFunction<
  MagicLinkLoginMutation,
  MagicLinkLoginMutationVariables
>;
export function useMagicLinkLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MagicLinkLoginMutation,
    MagicLinkLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MagicLinkLoginMutation,
    MagicLinkLoginMutationVariables
  >(MagicLinkLoginDocument, options);
}
export type MagicLinkLoginMutationHookResult = ReturnType<
  typeof useMagicLinkLoginMutation
>;
export type MagicLinkLoginMutationResult =
  Apollo.MutationResult<MagicLinkLoginMutation>;
export type MagicLinkLoginMutationOptions = Apollo.BaseMutationOptions<
  MagicLinkLoginMutation,
  MagicLinkLoginMutationVariables
>;
export const RefreshTokenDocument = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
    }
  }
`;
export type RefreshTokenMutationFn = Apollo.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export function useRefreshTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, options);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult =
  Apollo.MutationResult<RefreshTokenMutation>;
export type RefreshTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const RequestPasswordResetDocument = gql`
  mutation RequestPasswordReset($email: String!) {
    requestPasswordReset(email: $email) {
      ok
    }
  }
`;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export function useRequestPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestPasswordResetMutation,
    RequestPasswordResetMutationVariables
  >(RequestPasswordResetDocument, options);
}
export type RequestPasswordResetMutationHookResult = ReturnType<
  typeof useRequestPasswordResetMutation
>;
export type RequestPasswordResetMutationResult =
  Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  RequestPasswordResetMutation,
  RequestPasswordResetMutationVariables
>;
export const RevokeSessionDocument = gql`
  mutation RevokeSession($sessionId: String!) {
    revokeSession(sessionId: $sessionId) {
      ok
    }
  }
`;
export type RevokeSessionMutationFn = Apollo.MutationFunction<
  RevokeSessionMutation,
  RevokeSessionMutationVariables
>;
export function useRevokeSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeSessionMutation,
    RevokeSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeSessionMutation,
    RevokeSessionMutationVariables
  >(RevokeSessionDocument, options);
}
export type RevokeSessionMutationHookResult = ReturnType<
  typeof useRevokeSessionMutation
>;
export type RevokeSessionMutationResult =
  Apollo.MutationResult<RevokeSessionMutation>;
export type RevokeSessionMutationOptions = Apollo.BaseMutationOptions<
  RevokeSessionMutation,
  RevokeSessionMutationVariables
>;
export const SaveSessionDocument = gql`
  mutation SaveSession($sessionId: String!, $refreshToken: String!) {
    saveSession(sessionId: $sessionId, refreshToken: $refreshToken) {
      ok
    }
  }
`;
export type SaveSessionMutationFn = Apollo.MutationFunction<
  SaveSessionMutation,
  SaveSessionMutationVariables
>;
export function useSaveSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SaveSessionMutation,
    SaveSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SaveSessionMutation, SaveSessionMutationVariables>(
    SaveSessionDocument,
    options
  );
}
export type SaveSessionMutationHookResult = ReturnType<
  typeof useSaveSessionMutation
>;
export type SaveSessionMutationResult =
  Apollo.MutationResult<SaveSessionMutation>;
export type SaveSessionMutationOptions = Apollo.BaseMutationOptions<
  SaveSessionMutation,
  SaveSessionMutationVariables
>;
export const SessionDocument = gql`
  query Session($sessionId: String!) {
    session(sessionId: $sessionId) {
      ...UserSessionFields
    }
  }
  ${UserSessionFieldsFragmentDoc}
`;
export function useSessionQuery(
  baseOptions: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionQuery, SessionQueryVariables>(
    SessionDocument,
    options
  );
}
export function useSessionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(
    SessionDocument,
    options
  );
}
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionQueryResult = Apollo.QueryResult<
  SessionQuery,
  SessionQueryVariables
>;
export const SignUpDocument = gql`
  mutation SignUp(
    $name: String!
    $email: String!
    $password: String!
    $asMerchant: Boolean!
    $merchantName: String
    $merchantType: String
  ) {
    signUp(
      name: $name
      email: $email
      password: $password
      asMerchant: $asMerchant
      merchantName: $merchantName
      merchantType: $merchantType
    ) {
      token
      session {
        ...UserSessionFields
      }
    }
  }
  ${UserSessionFieldsFragmentDoc}
`;
export type SignUpMutationFn = Apollo.MutationFunction<
  SignUpMutation,
  SignUpMutationVariables
>;
export function useSignUpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpMutation,
    SignUpMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(
    SignUpDocument,
    options
  );
}
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const OrderSaleChannelDetailsDocument = gql`
  query OrderSaleChannelDetails($businessId: String!) {
    order(businessId: $businessId) {
      id
      user {
        id
        name
        email
      }
      isTesting
      salesChannel {
        merchant {
          id
          name
        }
        id
        preQualFormId
        qualificationFormId
        paymentFlowFormId
      }
    }
  }
`;
export function useOrderSaleChannelDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    OrderSaleChannelDetailsQuery,
    OrderSaleChannelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    OrderSaleChannelDetailsQuery,
    OrderSaleChannelDetailsQueryVariables
  >(OrderSaleChannelDetailsDocument, options);
}
export function useOrderSaleChannelDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OrderSaleChannelDetailsQuery,
    OrderSaleChannelDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    OrderSaleChannelDetailsQuery,
    OrderSaleChannelDetailsQueryVariables
  >(OrderSaleChannelDetailsDocument, options);
}
export type OrderSaleChannelDetailsQueryHookResult = ReturnType<
  typeof useOrderSaleChannelDetailsQuery
>;
export type OrderSaleChannelDetailsLazyQueryHookResult = ReturnType<
  typeof useOrderSaleChannelDetailsLazyQuery
>;
export type OrderSaleChannelDetailsQueryResult = Apollo.QueryResult<
  OrderSaleChannelDetailsQuery,
  OrderSaleChannelDetailsQueryVariables
>;
export const TryFinalizeShopifyPaymentDocument = gql`
  mutation TryFinalizeShopifyPayment($businessId: String!) {
    tryFinalizeShopifyPayment(businessId: $businessId) {
      ok
      redirectUrl
      errors {
        code
        message
      }
    }
  }
`;
export type TryFinalizeShopifyPaymentMutationFn = Apollo.MutationFunction<
  TryFinalizeShopifyPaymentMutation,
  TryFinalizeShopifyPaymentMutationVariables
>;
export function useTryFinalizeShopifyPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TryFinalizeShopifyPaymentMutation,
    TryFinalizeShopifyPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TryFinalizeShopifyPaymentMutation,
    TryFinalizeShopifyPaymentMutationVariables
  >(TryFinalizeShopifyPaymentDocument, options);
}
export type TryFinalizeShopifyPaymentMutationHookResult = ReturnType<
  typeof useTryFinalizeShopifyPaymentMutation
>;
export type TryFinalizeShopifyPaymentMutationResult =
  Apollo.MutationResult<TryFinalizeShopifyPaymentMutation>;
export type TryFinalizeShopifyPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    TryFinalizeShopifyPaymentMutation,
    TryFinalizeShopifyPaymentMutationVariables
  >;
export const CurrentUserOrdersDocument = gql`
  query CurrentUserOrders {
    currentUserQualifications {
      id
      businessId
      createdAt
      status
      salesChannel {
        name
        id
      }
      lmnVerification {
        id
        status
        reviewedAt
        lmn {
          id
        }
      }
    }
    currentUserOrders {
      id
      businessId
      createdAt
      user {
        id
        name
        email
      }
      isTesting
      totalAmount
      externalId
      createdAt
      updatedAt
      status
      salesChannel {
        name
        id
      }
      lmnVerification {
        id
        status
        reviewedAt
        client {
          id
        }
        createdAt
        updatedAt
        lmn {
          id
        }
      }
    }
  }
`;
export function useCurrentUserOrdersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserOrdersQuery,
    CurrentUserOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentUserOrdersQuery,
    CurrentUserOrdersQueryVariables
  >(CurrentUserOrdersDocument, options);
}
export function useCurrentUserOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserOrdersQuery,
    CurrentUserOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentUserOrdersQuery,
    CurrentUserOrdersQueryVariables
  >(CurrentUserOrdersDocument, options);
}
export type CurrentUserOrdersQueryHookResult = ReturnType<
  typeof useCurrentUserOrdersQuery
>;
export type CurrentUserOrdersLazyQueryHookResult = ReturnType<
  typeof useCurrentUserOrdersLazyQuery
>;
export type CurrentUserOrdersQueryResult = Apollo.QueryResult<
  CurrentUserOrdersQuery,
  CurrentUserOrdersQueryVariables
>;
export const MerchantPreQualificationsDocument = gql`
  query MerchantPreQualifications($salesChannelId: String!) {
    merchantPreQualifications(salesChannelId: $salesChannelId) {
      id
      businessId
      user {
        name
        email
      }
      status
    }
  }
`;
export function useMerchantPreQualificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MerchantPreQualificationsQuery,
    MerchantPreQualificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MerchantPreQualificationsQuery,
    MerchantPreQualificationsQueryVariables
  >(MerchantPreQualificationsDocument, options);
}
export function useMerchantPreQualificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MerchantPreQualificationsQuery,
    MerchantPreQualificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MerchantPreQualificationsQuery,
    MerchantPreQualificationsQueryVariables
  >(MerchantPreQualificationsDocument, options);
}
export type MerchantPreQualificationsQueryHookResult = ReturnType<
  typeof useMerchantPreQualificationsQuery
>;
export type MerchantPreQualificationsLazyQueryHookResult = ReturnType<
  typeof useMerchantPreQualificationsLazyQuery
>;
export type MerchantPreQualificationsQueryResult = Apollo.QueryResult<
  MerchantPreQualificationsQuery,
  MerchantPreQualificationsQueryVariables
>;
export const CreateStripeLinkDocument = gql`
  mutation CreateStripeLink($returnUrl: String!, $merchantId: Int!) {
    createStripeLink(returnUrl: $returnUrl, merchantId: $merchantId) {
      url
      ok
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type CreateStripeLinkMutationFn = Apollo.MutationFunction<
  CreateStripeLinkMutation,
  CreateStripeLinkMutationVariables
>;
export function useCreateStripeLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateStripeLinkMutation,
    CreateStripeLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateStripeLinkMutation,
    CreateStripeLinkMutationVariables
  >(CreateStripeLinkDocument, options);
}
export type CreateStripeLinkMutationHookResult = ReturnType<
  typeof useCreateStripeLinkMutation
>;
export type CreateStripeLinkMutationResult =
  Apollo.MutationResult<CreateStripeLinkMutation>;
export type CreateStripeLinkMutationOptions = Apollo.BaseMutationOptions<
  CreateStripeLinkMutation,
  CreateStripeLinkMutationVariables
>;
export const RefreshStripeStatusDocument = gql`
  mutation RefreshStripeStatus($merchantId: Int!) {
    refreshStripeStatus(merchantId: $merchantId) {
      ready
      submitted
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type RefreshStripeStatusMutationFn = Apollo.MutationFunction<
  RefreshStripeStatusMutation,
  RefreshStripeStatusMutationVariables
>;
export function useRefreshStripeStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshStripeStatusMutation,
    RefreshStripeStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshStripeStatusMutation,
    RefreshStripeStatusMutationVariables
  >(RefreshStripeStatusDocument, options);
}
export type RefreshStripeStatusMutationHookResult = ReturnType<
  typeof useRefreshStripeStatusMutation
>;
export type RefreshStripeStatusMutationResult =
  Apollo.MutationResult<RefreshStripeStatusMutation>;
export type RefreshStripeStatusMutationOptions = Apollo.BaseMutationOptions<
  RefreshStripeStatusMutation,
  RefreshStripeStatusMutationVariables
>;
export const StoreOrdersDocument = gql`
  query StoreOrders(
    $businessId: String!
    $searchString: String
    $currentPage: Int
    $pageSize: Int
  ) {
    storeOrders(
      businessId: $businessId
      searchString: $searchString
      currentPage: $currentPage
      pageSize: $pageSize
    ) {
      orders {
        id
        businessId
        createdAt
        user {
          id
          name
          email
        }
        isTesting
        recurring
        totalAmount
        externalId
        createdAt
        updatedAt
        status
        salesChannel {
          name
          id
        }
        paidWith
      }
      currentPage
      pageSize
      count
    }
  }
`;
export function useStoreOrdersQuery(
  baseOptions: Apollo.QueryHookOptions<
    StoreOrdersQuery,
    StoreOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<StoreOrdersQuery, StoreOrdersQueryVariables>(
    StoreOrdersDocument,
    options
  );
}
export function useStoreOrdersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    StoreOrdersQuery,
    StoreOrdersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<StoreOrdersQuery, StoreOrdersQueryVariables>(
    StoreOrdersDocument,
    options
  );
}
export type StoreOrdersQueryHookResult = ReturnType<typeof useStoreOrdersQuery>;
export type StoreOrdersLazyQueryHookResult = ReturnType<
  typeof useStoreOrdersLazyQuery
>;
export type StoreOrdersQueryResult = Apollo.QueryResult<
  StoreOrdersQuery,
  StoreOrdersQueryVariables
>;
export const PromoDetailsByBusinessIdDocument = gql`
  query PromoDetailsByBusinessId($businessId: String!) {
    salesChannelByBusinessId(businessId: $businessId) {
      externalSecret
      linkableQualificationId
    }
  }
`;
export function usePromoDetailsByBusinessIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    PromoDetailsByBusinessIdQuery,
    PromoDetailsByBusinessIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PromoDetailsByBusinessIdQuery,
    PromoDetailsByBusinessIdQueryVariables
  >(PromoDetailsByBusinessIdDocument, options);
}
export function usePromoDetailsByBusinessIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PromoDetailsByBusinessIdQuery,
    PromoDetailsByBusinessIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PromoDetailsByBusinessIdQuery,
    PromoDetailsByBusinessIdQueryVariables
  >(PromoDetailsByBusinessIdDocument, options);
}
export type PromoDetailsByBusinessIdQueryHookResult = ReturnType<
  typeof usePromoDetailsByBusinessIdQuery
>;
export type PromoDetailsByBusinessIdLazyQueryHookResult = ReturnType<
  typeof usePromoDetailsByBusinessIdLazyQuery
>;
export type PromoDetailsByBusinessIdQueryResult = Apollo.QueryResult<
  PromoDetailsByBusinessIdQuery,
  PromoDetailsByBusinessIdQueryVariables
>;
export const MerchantOnboardingDocument = gql`
  query MerchantOnboarding($merchantId: String!) {
    merchantOnboarding(merchantId: $merchantId) {
      showQualifications
      needsCcEntry
      merchantEmail
      onboardingType
      invoicingEnabled
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export function useMerchantOnboardingQuery(
  baseOptions: Apollo.QueryHookOptions<
    MerchantOnboardingQuery,
    MerchantOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MerchantOnboardingQuery,
    MerchantOnboardingQueryVariables
  >(MerchantOnboardingDocument, options);
}
export function useMerchantOnboardingLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MerchantOnboardingQuery,
    MerchantOnboardingQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MerchantOnboardingQuery,
    MerchantOnboardingQueryVariables
  >(MerchantOnboardingDocument, options);
}
export type MerchantOnboardingQueryHookResult = ReturnType<
  typeof useMerchantOnboardingQuery
>;
export type MerchantOnboardingLazyQueryHookResult = ReturnType<
  typeof useMerchantOnboardingLazyQuery
>;
export type MerchantOnboardingQueryResult = Apollo.QueryResult<
  MerchantOnboardingQuery,
  MerchantOnboardingQueryVariables
>;
export const MerchantQualificationsDocument = gql`
  query MerchantQualifications(
    $salesChannelId: String!
    $searchString: String
    $currentPage: Int
    $pageSize: Int
  ) {
    merchantQualifications(
      salesChannelId: $salesChannelId
      searchString: $searchString
      currentPage: $currentPage
      pageSize: $pageSize
    ) {
      qualifications {
        id
        businessId
        createdAt
        lmnReviewedAt
        utm
        externalUserId
        user {
          name
          email
        }
        status
        customerStatus
      }
      currentPage
      pageSize
      count
    }
  }
`;
export function useMerchantQualificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MerchantQualificationsQuery,
    MerchantQualificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    MerchantQualificationsQuery,
    MerchantQualificationsQueryVariables
  >(MerchantQualificationsDocument, options);
}
export function useMerchantQualificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MerchantQualificationsQuery,
    MerchantQualificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    MerchantQualificationsQuery,
    MerchantQualificationsQueryVariables
  >(MerchantQualificationsDocument, options);
}
export type MerchantQualificationsQueryHookResult = ReturnType<
  typeof useMerchantQualificationsQuery
>;
export type MerchantQualificationsLazyQueryHookResult = ReturnType<
  typeof useMerchantQualificationsLazyQuery
>;
export type MerchantQualificationsQueryResult = Apollo.QueryResult<
  MerchantQualificationsQuery,
  MerchantQualificationsQueryVariables
>;
export const EndSubscriptionDocument = gql`
  mutation EndSubscription($orderBusinessId: String!) {
    endSubscription(orderBusinessId: $orderBusinessId) {
      ok
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type EndSubscriptionMutationFn = Apollo.MutationFunction<
  EndSubscriptionMutation,
  EndSubscriptionMutationVariables
>;
export function useEndSubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    EndSubscriptionMutation,
    EndSubscriptionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    EndSubscriptionMutation,
    EndSubscriptionMutationVariables
  >(EndSubscriptionDocument, options);
}
export type EndSubscriptionMutationHookResult = ReturnType<
  typeof useEndSubscriptionMutation
>;
export type EndSubscriptionMutationResult =
  Apollo.MutationResult<EndSubscriptionMutation>;
export type EndSubscriptionMutationOptions = Apollo.BaseMutationOptions<
  EndSubscriptionMutation,
  EndSubscriptionMutationVariables
>;
export const CreatePreQualificationSessionFromLinkDocument = gql`
  mutation createPreQualificationSessionFromLink(
    $linkableQualId: String!
    $utm: String
    $persistentBrowserId: String
    $externalUserId: String
    $successAction: QualificationSuccessActionType
  ) {
    createQualificationSessionFromLink(
      linkableQualId: $linkableQualId
      utm: $utm
      persistentBrowserId: $persistentBrowserId
      externalUserId: $externalUserId
      sessionType: MerchantPreQualification
      successAction: $successAction
    ) {
      qsBusinessId
      errors {
        messages
      }
    }
  }
`;
export type CreatePreQualificationSessionFromLinkMutationFn =
  Apollo.MutationFunction<
    CreatePreQualificationSessionFromLinkMutation,
    CreatePreQualificationSessionFromLinkMutationVariables
  >;
export function useCreatePreQualificationSessionFromLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePreQualificationSessionFromLinkMutation,
    CreatePreQualificationSessionFromLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePreQualificationSessionFromLinkMutation,
    CreatePreQualificationSessionFromLinkMutationVariables
  >(CreatePreQualificationSessionFromLinkDocument, options);
}
export type CreatePreQualificationSessionFromLinkMutationHookResult =
  ReturnType<typeof useCreatePreQualificationSessionFromLinkMutation>;
export type CreatePreQualificationSessionFromLinkMutationResult =
  Apollo.MutationResult<CreatePreQualificationSessionFromLinkMutation>;
export type CreatePreQualificationSessionFromLinkMutationOptions =
  Apollo.BaseMutationOptions<
    CreatePreQualificationSessionFromLinkMutation,
    CreatePreQualificationSessionFromLinkMutationVariables
  >;
export const PreQualificationSessionDetailsDocument = gql`
  query PreQualificationSessionDetails($businessId: String!) {
    qualificationSession(businessId: $businessId) {
      formId
      merchantName
      variables
      status
      retryUrl
      salesChannelType
      successRedirectUrl
      failureRedirectUrl
      successAction
    }
  }
`;
export function usePreQualificationSessionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PreQualificationSessionDetailsQuery,
    PreQualificationSessionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    PreQualificationSessionDetailsQuery,
    PreQualificationSessionDetailsQueryVariables
  >(PreQualificationSessionDetailsDocument, options);
}
export function usePreQualificationSessionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PreQualificationSessionDetailsQuery,
    PreQualificationSessionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    PreQualificationSessionDetailsQuery,
    PreQualificationSessionDetailsQueryVariables
  >(PreQualificationSessionDetailsDocument, options);
}
export type PreQualificationSessionDetailsQueryHookResult = ReturnType<
  typeof usePreQualificationSessionDetailsQuery
>;
export type PreQualificationSessionDetailsLazyQueryHookResult = ReturnType<
  typeof usePreQualificationSessionDetailsLazyQuery
>;
export type PreQualificationSessionDetailsQueryResult = Apollo.QueryResult<
  PreQualificationSessionDetailsQuery,
  PreQualificationSessionDetailsQueryVariables
>;
export const AssignHcpToLmnVerificationDocument = gql`
  mutation AssignHcpToLmnVerification($lmnVerificationId: String!) {
    assignHcpToLmnVerification(lmnVerificationId: $lmnVerificationId) {
      lmnVerification {
        id
      }
    }
  }
`;
export type AssignHcpToLmnVerificationMutationFn = Apollo.MutationFunction<
  AssignHcpToLmnVerificationMutation,
  AssignHcpToLmnVerificationMutationVariables
>;
export function useAssignHcpToLmnVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AssignHcpToLmnVerificationMutation,
    AssignHcpToLmnVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AssignHcpToLmnVerificationMutation,
    AssignHcpToLmnVerificationMutationVariables
  >(AssignHcpToLmnVerificationDocument, options);
}
export type AssignHcpToLmnVerificationMutationHookResult = ReturnType<
  typeof useAssignHcpToLmnVerificationMutation
>;
export type AssignHcpToLmnVerificationMutationResult =
  Apollo.MutationResult<AssignHcpToLmnVerificationMutation>;
export type AssignHcpToLmnVerificationMutationOptions =
  Apollo.BaseMutationOptions<
    AssignHcpToLmnVerificationMutation,
    AssignHcpToLmnVerificationMutationVariables
  >;
export const CreateLetterOfMedicalNecessityDocument = gql`
  mutation CreateLetterOfMedicalNecessity(
    $issuedDate: Date!
    $expiredDate: Date!
    $patientId: Int!
    $merchantId: Int!
    $lmnDiagnosisId: Int
    $lmnFile: Upload
  ) {
    createLetterOfMedicalNecessity(
      issuedDate: $issuedDate
      expiredDate: $expiredDate
      patientId: $patientId
      merchantId: $merchantId
      lmnDiagnosisId: $lmnDiagnosisId
      lmnFile: $lmnFile
    ) {
      ok
      lmnId
      errors {
        field
        message
        messages
        code
      }
    }
  }
`;
export type CreateLetterOfMedicalNecessityMutationFn = Apollo.MutationFunction<
  CreateLetterOfMedicalNecessityMutation,
  CreateLetterOfMedicalNecessityMutationVariables
>;
export function useCreateLetterOfMedicalNecessityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLetterOfMedicalNecessityMutation,
    CreateLetterOfMedicalNecessityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateLetterOfMedicalNecessityMutation,
    CreateLetterOfMedicalNecessityMutationVariables
  >(CreateLetterOfMedicalNecessityDocument, options);
}
export type CreateLetterOfMedicalNecessityMutationHookResult = ReturnType<
  typeof useCreateLetterOfMedicalNecessityMutation
>;
export type CreateLetterOfMedicalNecessityMutationResult =
  Apollo.MutationResult<CreateLetterOfMedicalNecessityMutation>;
export type CreateLetterOfMedicalNecessityMutationOptions =
  Apollo.BaseMutationOptions<
    CreateLetterOfMedicalNecessityMutation,
    CreateLetterOfMedicalNecessityMutationVariables
  >;
export const CurrentHcpLmnVerificationsDocument = gql`
  query CurrentHcpLmnVerifications(
    $currentPage: Int!
    $pageSize: Int!
    $searchString: String
  ) {
    currentHcpLmnVerifications(
      currentPage: $currentPage
      pageSize: $pageSize
      searchString: $searchString
    ) {
      verifications {
        id
        client {
          name
          id
        }
        order {
          id
          businessId
          externalId
          salesChannel {
            id
            name
          }
        }
        status
        reviewedAt
        verifiedBy {
          id
          name
          user {
            id
            name
          }
        }
        merchantName
      }
      currentPage
      pageSize
      count
    }
  }
`;
export function useCurrentHcpLmnVerificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CurrentHcpLmnVerificationsQuery,
    CurrentHcpLmnVerificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    CurrentHcpLmnVerificationsQuery,
    CurrentHcpLmnVerificationsQueryVariables
  >(CurrentHcpLmnVerificationsDocument, options);
}
export function useCurrentHcpLmnVerificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentHcpLmnVerificationsQuery,
    CurrentHcpLmnVerificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    CurrentHcpLmnVerificationsQuery,
    CurrentHcpLmnVerificationsQueryVariables
  >(CurrentHcpLmnVerificationsDocument, options);
}
export type CurrentHcpLmnVerificationsQueryHookResult = ReturnType<
  typeof useCurrentHcpLmnVerificationsQuery
>;
export type CurrentHcpLmnVerificationsLazyQueryHookResult = ReturnType<
  typeof useCurrentHcpLmnVerificationsLazyQuery
>;
export type CurrentHcpLmnVerificationsQueryResult = Apollo.QueryResult<
  CurrentHcpLmnVerificationsQuery,
  CurrentHcpLmnVerificationsQueryVariables
>;
export const LmnVerificationDetailDocument = gql`
  query LmnVerificationDetail($lmnVerificationId: String!) {
    lmnVerificationDetail(lmnVerificationId: $lmnVerificationId) {
      lmnVerification {
        id
        status
        reviewedAt
        expiredAt
        client {
          id
          name
          email
        }
        order {
          id
          orderItems {
            id
            name
            quantity
            externalId
          }
        }
        verifiedBy {
          id
          name
          user {
            id
          }
        }
        lmn {
          id
        }
      }
      evaluationFormData {
        formId
        answers {
          questionId
          values
        }
      }
      intakeFormData {
        content
        answers
      }
      merchantName
      merchantId
      merchantHasRecommendedProducts
      previousUnclaimedRecordId
      nextUnclaimedRecordId
    }
  }
`;
export function useLmnVerificationDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    LmnVerificationDetailQuery,
    LmnVerificationDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LmnVerificationDetailQuery,
    LmnVerificationDetailQueryVariables
  >(LmnVerificationDetailDocument, options);
}
export function useLmnVerificationDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LmnVerificationDetailQuery,
    LmnVerificationDetailQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LmnVerificationDetailQuery,
    LmnVerificationDetailQueryVariables
  >(LmnVerificationDetailDocument, options);
}
export type LmnVerificationDetailQueryHookResult = ReturnType<
  typeof useLmnVerificationDetailQuery
>;
export type LmnVerificationDetailLazyQueryHookResult = ReturnType<
  typeof useLmnVerificationDetailLazyQuery
>;
export type LmnVerificationDetailQueryResult = Apollo.QueryResult<
  LmnVerificationDetailQuery,
  LmnVerificationDetailQueryVariables
>;
export const LmnUnclaimedDocument = gql`
  query LmnUnclaimed($lmnVerificationId: String!) {
    lmnVerificationDetail(lmnVerificationId: $lmnVerificationId) {
      previousUnclaimedRecordId
      nextUnclaimedRecordId
    }
  }
`;
export function useLmnUnclaimedQuery(
  baseOptions: Apollo.QueryHookOptions<
    LmnUnclaimedQuery,
    LmnUnclaimedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LmnUnclaimedQuery, LmnUnclaimedQueryVariables>(
    LmnUnclaimedDocument,
    options
  );
}
export function useLmnUnclaimedLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LmnUnclaimedQuery,
    LmnUnclaimedQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LmnUnclaimedQuery, LmnUnclaimedQueryVariables>(
    LmnUnclaimedDocument,
    options
  );
}
export type LmnUnclaimedQueryHookResult = ReturnType<
  typeof useLmnUnclaimedQuery
>;
export type LmnUnclaimedLazyQueryHookResult = ReturnType<
  typeof useLmnUnclaimedLazyQuery
>;
export type LmnUnclaimedQueryResult = Apollo.QueryResult<
  LmnUnclaimedQuery,
  LmnUnclaimedQueryVariables
>;
export const PatientLmnsDocument = gql`
  query PatientLmns($patientId: String!) {
    patientLmns(patientId: $patientId) {
      id
      issuedBy {
        id
        name
      }
      url
      issuedDate
      expiredDate
    }
  }
`;
export function usePatientLmnsQuery(
  baseOptions: Apollo.QueryHookOptions<
    PatientLmnsQuery,
    PatientLmnsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<PatientLmnsQuery, PatientLmnsQueryVariables>(
    PatientLmnsDocument,
    options
  );
}
export function usePatientLmnsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    PatientLmnsQuery,
    PatientLmnsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<PatientLmnsQuery, PatientLmnsQueryVariables>(
    PatientLmnsDocument,
    options
  );
}
export type PatientLmnsQueryHookResult = ReturnType<typeof usePatientLmnsQuery>;
export type PatientLmnsLazyQueryHookResult = ReturnType<
  typeof usePatientLmnsLazyQuery
>;
export type PatientLmnsQueryResult = Apollo.QueryResult<
  PatientLmnsQuery,
  PatientLmnsQueryVariables
>;
export const UpdateLetterOfMedicalNecessityDocument = gql`
  mutation UpdateLetterOfMedicalNecessity(
    $lmnVerificationId: Int!
    $lmnId: Int!
    $comment: String
  ) {
    updateLetterOfMedicalNecessity(
      lmnVerificationId: $lmnVerificationId
      lmnId: $lmnId
      comment: $comment
    ) {
      ok
      lmnVerification {
        status
        id
      }
      errors {
        field
        message
        messages
        code
      }
    }
  }
`;
export type UpdateLetterOfMedicalNecessityMutationFn = Apollo.MutationFunction<
  UpdateLetterOfMedicalNecessityMutation,
  UpdateLetterOfMedicalNecessityMutationVariables
>;
export function useUpdateLetterOfMedicalNecessityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLetterOfMedicalNecessityMutation,
    UpdateLetterOfMedicalNecessityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLetterOfMedicalNecessityMutation,
    UpdateLetterOfMedicalNecessityMutationVariables
  >(UpdateLetterOfMedicalNecessityDocument, options);
}
export type UpdateLetterOfMedicalNecessityMutationHookResult = ReturnType<
  typeof useUpdateLetterOfMedicalNecessityMutation
>;
export type UpdateLetterOfMedicalNecessityMutationResult =
  Apollo.MutationResult<UpdateLetterOfMedicalNecessityMutation>;
export type UpdateLetterOfMedicalNecessityMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateLetterOfMedicalNecessityMutation,
    UpdateLetterOfMedicalNecessityMutationVariables
  >;
export const VerifyLetterOfMedicalNecessityDocument = gql`
  mutation VerifyLetterOfMedicalNecessity(
    $expiredAt: Date!
    $lmnId: Int
    $verificationId: Int!
    $status: LMNVerificationStatus!
  ) {
    verifyLetterOfMedicalNecessity(
      expiredAt: $expiredAt
      lmnId: $lmnId
      verificationId: $verificationId
      status: $status
    ) {
      ok
      lmnVerification {
        status
        id
      }
      errors {
        field
        message
        messages
        code
      }
    }
  }
`;
export type VerifyLetterOfMedicalNecessityMutationFn = Apollo.MutationFunction<
  VerifyLetterOfMedicalNecessityMutation,
  VerifyLetterOfMedicalNecessityMutationVariables
>;
export function useVerifyLetterOfMedicalNecessityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyLetterOfMedicalNecessityMutation,
    VerifyLetterOfMedicalNecessityMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyLetterOfMedicalNecessityMutation,
    VerifyLetterOfMedicalNecessityMutationVariables
  >(VerifyLetterOfMedicalNecessityDocument, options);
}
export type VerifyLetterOfMedicalNecessityMutationHookResult = ReturnType<
  typeof useVerifyLetterOfMedicalNecessityMutation
>;
export type VerifyLetterOfMedicalNecessityMutationResult =
  Apollo.MutationResult<VerifyLetterOfMedicalNecessityMutation>;
export type VerifyLetterOfMedicalNecessityMutationOptions =
  Apollo.BaseMutationOptions<
    VerifyLetterOfMedicalNecessityMutation,
    VerifyLetterOfMedicalNecessityMutationVariables
  >;
export const LmnDiagnosisQueryDocument = gql`
  query LmnDiagnosisQuery {
    lmnDiagnoses {
      diagnosisId
      name
    }
  }
`;
export function useLmnDiagnosisQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LmnDiagnosisQueryQuery,
    LmnDiagnosisQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    LmnDiagnosisQueryQuery,
    LmnDiagnosisQueryQueryVariables
  >(LmnDiagnosisQueryDocument, options);
}
export function useLmnDiagnosisQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LmnDiagnosisQueryQuery,
    LmnDiagnosisQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    LmnDiagnosisQueryQuery,
    LmnDiagnosisQueryQueryVariables
  >(LmnDiagnosisQueryDocument, options);
}
export type LmnDiagnosisQueryQueryHookResult = ReturnType<
  typeof useLmnDiagnosisQueryQuery
>;
export type LmnDiagnosisQueryLazyQueryHookResult = ReturnType<
  typeof useLmnDiagnosisQueryLazyQuery
>;
export type LmnDiagnosisQueryQueryResult = Apollo.QueryResult<
  LmnDiagnosisQueryQuery,
  LmnDiagnosisQueryQueryVariables
>;
export const CreateQualificationSessionFromAffiliateDocument = gql`
  mutation createQualificationSessionFromAffiliate(
    $affiliateOrgSlug: String!
    $salesChannelQualId: String!
    $persistentBrowserId: String
    $utm: String
  ) {
    createQualificationSessionFromAffiliate(
      affiliateOrgSlug: $affiliateOrgSlug
      salesChannelQualId: $salesChannelQualId
      persistentBrowserId: $persistentBrowserId
      utm: $utm
    ) {
      qsBusinessId
      errors {
        messages
      }
    }
  }
`;
export type CreateQualificationSessionFromAffiliateMutationFn =
  Apollo.MutationFunction<
    CreateQualificationSessionFromAffiliateMutation,
    CreateQualificationSessionFromAffiliateMutationVariables
  >;
export function useCreateQualificationSessionFromAffiliateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQualificationSessionFromAffiliateMutation,
    CreateQualificationSessionFromAffiliateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQualificationSessionFromAffiliateMutation,
    CreateQualificationSessionFromAffiliateMutationVariables
  >(CreateQualificationSessionFromAffiliateDocument, options);
}
export type CreateQualificationSessionFromAffiliateMutationHookResult =
  ReturnType<typeof useCreateQualificationSessionFromAffiliateMutation>;
export type CreateQualificationSessionFromAffiliateMutationResult =
  Apollo.MutationResult<CreateQualificationSessionFromAffiliateMutation>;
export type CreateQualificationSessionFromAffiliateMutationOptions =
  Apollo.BaseMutationOptions<
    CreateQualificationSessionFromAffiliateMutation,
    CreateQualificationSessionFromAffiliateMutationVariables
  >;
export const CreateQualificationSessionFromLinkDocument = gql`
  mutation createQualificationSessionFromLink(
    $linkableQualId: String!
    $persistentBrowserId: String
    $externalUserId: String
    $signature: String
    $utm: String
  ) {
    createQualificationSessionFromLink(
      linkableQualId: $linkableQualId
      persistentBrowserId: $persistentBrowserId
      externalUserId: $externalUserId
      signature: $signature
      utm: $utm
      sessionType: MerchantQualification
    ) {
      qsBusinessId
      errors {
        messages
      }
    }
  }
`;
export type CreateQualificationSessionFromLinkMutationFn =
  Apollo.MutationFunction<
    CreateQualificationSessionFromLinkMutation,
    CreateQualificationSessionFromLinkMutationVariables
  >;
export function useCreateQualificationSessionFromLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateQualificationSessionFromLinkMutation,
    CreateQualificationSessionFromLinkMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateQualificationSessionFromLinkMutation,
    CreateQualificationSessionFromLinkMutationVariables
  >(CreateQualificationSessionFromLinkDocument, options);
}
export type CreateQualificationSessionFromLinkMutationHookResult = ReturnType<
  typeof useCreateQualificationSessionFromLinkMutation
>;
export type CreateQualificationSessionFromLinkMutationResult =
  Apollo.MutationResult<CreateQualificationSessionFromLinkMutation>;
export type CreateQualificationSessionFromLinkMutationOptions =
  Apollo.BaseMutationOptions<
    CreateQualificationSessionFromLinkMutation,
    CreateQualificationSessionFromLinkMutationVariables
  >;
export const ConfirmQualificationSessionPaymentDocument = gql`
  mutation ConfirmQualificationSessionPayment($qsBusinessId: String!) {
    confirmQualificationSessionPayment(qsBusinessId: $qsBusinessId) {
      ok
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type ConfirmQualificationSessionPaymentMutationFn =
  Apollo.MutationFunction<
    ConfirmQualificationSessionPaymentMutation,
    ConfirmQualificationSessionPaymentMutationVariables
  >;
export function useConfirmQualificationSessionPaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmQualificationSessionPaymentMutation,
    ConfirmQualificationSessionPaymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfirmQualificationSessionPaymentMutation,
    ConfirmQualificationSessionPaymentMutationVariables
  >(ConfirmQualificationSessionPaymentDocument, options);
}
export type ConfirmQualificationSessionPaymentMutationHookResult = ReturnType<
  typeof useConfirmQualificationSessionPaymentMutation
>;
export type ConfirmQualificationSessionPaymentMutationResult =
  Apollo.MutationResult<ConfirmQualificationSessionPaymentMutation>;
export type ConfirmQualificationSessionPaymentMutationOptions =
  Apollo.BaseMutationOptions<
    ConfirmQualificationSessionPaymentMutation,
    ConfirmQualificationSessionPaymentMutationVariables
  >;
export const ResolveQualificationClientSecretDocument = gql`
  mutation resolveQualificationClientSecret($qsBusinessId: String!) {
    resolveQualificationClientSecret(qsBusinessId: $qsBusinessId) {
      ok
      stripeClientSecret
      errors {
        field
        messages
        message
        code
      }
    }
  }
`;
export type ResolveQualificationClientSecretMutationFn =
  Apollo.MutationFunction<
    ResolveQualificationClientSecretMutation,
    ResolveQualificationClientSecretMutationVariables
  >;
export function useResolveQualificationClientSecretMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResolveQualificationClientSecretMutation,
    ResolveQualificationClientSecretMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResolveQualificationClientSecretMutation,
    ResolveQualificationClientSecretMutationVariables
  >(ResolveQualificationClientSecretDocument, options);
}
export type ResolveQualificationClientSecretMutationHookResult = ReturnType<
  typeof useResolveQualificationClientSecretMutation
>;
export type ResolveQualificationClientSecretMutationResult =
  Apollo.MutationResult<ResolveQualificationClientSecretMutation>;
export type ResolveQualificationClientSecretMutationOptions =
  Apollo.BaseMutationOptions<
    ResolveQualificationClientSecretMutation,
    ResolveQualificationClientSecretMutationVariables
  >;
export const QualificationSessionDetailsDocument = gql`
  query QualificationSessionDetails($businessId: String!) {
    qualificationSession(businessId: $businessId) {
      formId
      merchantName
      variables
      sessionType
      retryUrl
      businessId
      status
      successRedirectUrl
      failureRedirectUrl
      fee
      user {
        email
        name
      }
      successAction
      customerStatus
    }
  }
`;
export function useQualificationSessionDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    QualificationSessionDetailsQuery,
    QualificationSessionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    QualificationSessionDetailsQuery,
    QualificationSessionDetailsQueryVariables
  >(QualificationSessionDetailsDocument, options);
}
export function useQualificationSessionDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QualificationSessionDetailsQuery,
    QualificationSessionDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    QualificationSessionDetailsQuery,
    QualificationSessionDetailsQueryVariables
  >(QualificationSessionDetailsDocument, options);
}
export type QualificationSessionDetailsQueryHookResult = ReturnType<
  typeof useQualificationSessionDetailsQuery
>;
export type QualificationSessionDetailsLazyQueryHookResult = ReturnType<
  typeof useQualificationSessionDetailsLazyQuery
>;
export type QualificationSessionDetailsQueryResult = Apollo.QueryResult<
  QualificationSessionDetailsQuery,
  QualificationSessionDetailsQueryVariables
>;
export const ConnectSalesChannelToMerchantDocument = gql`
  mutation ConnectSalesChannelToMerchant(
    $salesChannelBusinessId: String!
    $merchantId: Int!
  ) {
    connectSalesChannelToMerchant(
      salesChannelBusinessId: $salesChannelBusinessId
      merchantId: $merchantId
    ) {
      ok
      errors {
        message
      }
      salesChannel {
        id
        shopifyShopAdminUrl
        merchant {
          id
          name
        }
      }
    }
  }
`;
export type ConnectSalesChannelToMerchantMutationFn = Apollo.MutationFunction<
  ConnectSalesChannelToMerchantMutation,
  ConnectSalesChannelToMerchantMutationVariables
>;
export function useConnectSalesChannelToMerchantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectSalesChannelToMerchantMutation,
    ConnectSalesChannelToMerchantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectSalesChannelToMerchantMutation,
    ConnectSalesChannelToMerchantMutationVariables
  >(ConnectSalesChannelToMerchantDocument, options);
}
export type ConnectSalesChannelToMerchantMutationHookResult = ReturnType<
  typeof useConnectSalesChannelToMerchantMutation
>;
export type ConnectSalesChannelToMerchantMutationResult =
  Apollo.MutationResult<ConnectSalesChannelToMerchantMutation>;
export type ConnectSalesChannelToMerchantMutationOptions =
  Apollo.BaseMutationOptions<
    ConnectSalesChannelToMerchantMutation,
    ConnectSalesChannelToMerchantMutationVariables
  >;
export const SalesChannelsByMerchantDocument = gql`
  query SalesChannelsByMerchant(
    $merchantId: String!
    $onlyShowVisibleToMerchant: Boolean!
  ) {
    salesChannelsByMerchant(
      merchantId: $merchantId
      onlyShowVisibleToMerchant: $onlyShowVisibleToMerchant
    ) {
      id
      name
      enabled
      isVisibleToMerchant
      qualificationUrl
      type
      businessId
    }
  }
`;
export function useSalesChannelsByMerchantQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesChannelsByMerchantQuery,
    SalesChannelsByMerchantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesChannelsByMerchantQuery,
    SalesChannelsByMerchantQueryVariables
  >(SalesChannelsByMerchantDocument, options);
}
export function useSalesChannelsByMerchantLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesChannelsByMerchantQuery,
    SalesChannelsByMerchantQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesChannelsByMerchantQuery,
    SalesChannelsByMerchantQueryVariables
  >(SalesChannelsByMerchantDocument, options);
}
export type SalesChannelsByMerchantQueryHookResult = ReturnType<
  typeof useSalesChannelsByMerchantQuery
>;
export type SalesChannelsByMerchantLazyQueryHookResult = ReturnType<
  typeof useSalesChannelsByMerchantLazyQuery
>;
export type SalesChannelsByMerchantQueryResult = Apollo.QueryResult<
  SalesChannelsByMerchantQuery,
  SalesChannelsByMerchantQueryVariables
>;
export const ShopifyAppInstallUrlDocument = gql`
  query ShopifyAppInstallUrl($shopSubdomain: String!) {
    shopifyAppInstallUrl(shopSubdomain: $shopSubdomain)
  }
`;
export function useShopifyAppInstallUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    ShopifyAppInstallUrlQuery,
    ShopifyAppInstallUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ShopifyAppInstallUrlQuery,
    ShopifyAppInstallUrlQueryVariables
  >(ShopifyAppInstallUrlDocument, options);
}
export function useShopifyAppInstallUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ShopifyAppInstallUrlQuery,
    ShopifyAppInstallUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ShopifyAppInstallUrlQuery,
    ShopifyAppInstallUrlQueryVariables
  >(ShopifyAppInstallUrlDocument, options);
}
export type ShopifyAppInstallUrlQueryHookResult = ReturnType<
  typeof useShopifyAppInstallUrlQuery
>;
export type ShopifyAppInstallUrlLazyQueryHookResult = ReturnType<
  typeof useShopifyAppInstallUrlLazyQuery
>;
export type ShopifyAppInstallUrlQueryResult = Apollo.QueryResult<
  ShopifyAppInstallUrlQuery,
  ShopifyAppInstallUrlQueryVariables
>;
export const SalesChannelByBusinessIdDocument = gql`
  query SalesChannelByBusinessId($businessId: String!) {
    salesChannelByBusinessId(businessId: $businessId) {
      id
      name
      enabled
      merchant {
        id
      }
      externalUrl
    }
  }
`;
export function useSalesChannelByBusinessIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    SalesChannelByBusinessIdQuery,
    SalesChannelByBusinessIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    SalesChannelByBusinessIdQuery,
    SalesChannelByBusinessIdQueryVariables
  >(SalesChannelByBusinessIdDocument, options);
}
export function useSalesChannelByBusinessIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SalesChannelByBusinessIdQuery,
    SalesChannelByBusinessIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    SalesChannelByBusinessIdQuery,
    SalesChannelByBusinessIdQueryVariables
  >(SalesChannelByBusinessIdDocument, options);
}
export type SalesChannelByBusinessIdQueryHookResult = ReturnType<
  typeof useSalesChannelByBusinessIdQuery
>;
export type SalesChannelByBusinessIdLazyQueryHookResult = ReturnType<
  typeof useSalesChannelByBusinessIdLazyQuery
>;
export type SalesChannelByBusinessIdQueryResult = Apollo.QueryResult<
  SalesChannelByBusinessIdQuery,
  SalesChannelByBusinessIdQueryVariables
>;
