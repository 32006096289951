import mixpanel, { Dict, Query } from 'mixpanel-browser';
import { captureException } from './Sentry';

const appEnvProduction = process.env.APP_ENV === 'production';
const enabled = process.env.NEXT_PUBLIC_MIXPANEL_ENABLED === 'true';
const isDebug = process.env.NEXT_PUBLIC_MIXPANEL_DEBUG === 'true';

if (enabled) {
  try {
    mixpanel.init(
      process.env.NEXT_PUBLIC_MIXPANEL_KEY ||
        'e88431b8e47c6d66462d692bbfb873e0',
      {
        debug: !appEnvProduction,
      }
    );
  } catch (e) {
    console.error('could not init mixpanel', e);
    captureException(e as Error);
  }
}

const safeTrack =
  <T extends (...args: any[]) => void>(name: string, fn: T) =>
  (...args: Parameters<T>) => {
    if (isDebug) {
      console.log('Mixpanel', name, args);
    }
    try {
      return fn(...args);
    } catch (e) {
      if (enabled) {
        console.error('could not track mixpanel', e);
        captureException(e as Error);
      }
    }
  };

const identify = safeTrack('identify', (id: string) => {
  mixpanel?.identify(id);
});

const alias = safeTrack('alias', (id: string) => {
  mixpanel?.alias(id);
});

const track = safeTrack('track', (name: string, props?: Dict) => {
  mixpanel?.track(name, props);
});

const track_links = safeTrack('track_links', (query: Query, name: string) => {
  mixpanel?.track_links(query, name, {
    referrer: document.referrer,
  });
});
const set_people = safeTrack('track_links', (query: Query, name: string) => {
  mixpanel?.track_links(query, name, {
    referrer: document.referrer,
  });
});

export const MixpanelAPI = {
  identify,
  alias,
  track,
  track_links,
  set_people,
};
