import moment from 'moment';
import { ParsedUrlQuery } from 'querystring';
import { v4 as uuidv4 } from 'uuid';
import {
  ChannelType,
  Maybe,
  MerchantCategory,
  UserSessionType,
} from '../graphql';

type StringDict = { [key: string]: string };

export const getStatusColor = (str: Maybe<string> | undefined) => {
  if (!str) {
    return '#718096';
  }
  switch (str.toLowerCase()) {
    case 'confirmed':
      return '#2650CB';
    case 'charged':
      return '#38A169';
    case 'success':
      return '#38A169';
    case 'verified':
      return '#38A169';
    case 'refunded':
      return '#d42054';
    case 'cancelled':
      return '#DD6B20';
    case 'rejected':
      return '#DD6B20';
    default:
      return '#718096';
  }
};

export const getPaidWithColor = (str: Maybe<string> | undefined) => {
  if (!str) {
    return '#718096';
  }

  switch (str.toLowerCase()) {
    case 'normalcard':
      return '#2650CB';
    case 'hsaorfsacard':
      return '#38A169';
    default:
      return '#718096';
  }
};

export const getUserRedirectURL = (
  currentSession: Partial<UserSessionType>
) => {
  // TODO: check permission of merchantMembers
  const { salesChannels, merchantMembers, affiliateOrganizationMembers } =
    currentSession;
  const isHcp = currentSession?.user?.isHcp;

  if (merchantMembers && merchantMembers[0]) {
    // TODO - this should come from the back-end
    const shopifyChannelId = salesChannels?.filter(
      (channel) => channel.type === ChannelType.Shopify
    )?.[0]?.businessId;

    if (shopifyChannelId) {
      return '/merchants/payments';
    } else {
      const merchant = merchantMembers[0].merchant;
      return merchant.category === MerchantCategory.Affiliate
        ? `/${merchant.affiliateOrganizationSlug}/affiliate/${merchant.id}/`
        : '/merchants/qualifications';
    }
  } else if (isHcp) {
    return '/providers/evaluations';
  } else if (affiliateOrganizationMembers && affiliateOrganizationMembers[0]) {
    return `/${affiliateOrganizationMembers[0].affiliateOrganization.slug}/management/`;
  } else {
    return '/dashboard/orders';
  }
};

export const createSId = () => uuidv4();

export const createExpiredDateForSId = () => {
  const expiredDate = new Date();
  expiredDate.setMonth(expiredDate.getMonth() + 1);
  return expiredDate;
};

export function isExpired(time: moment.Moment): boolean {
  return time && moment().isAfter(time);
}

/** Converts recurring property of price to user-friendly string */
export const recurringValueToUserFriendlyString = (
  recurring?: string | null
) => {
  switch (recurring) {
    case 'day':
      return 'Daily';
    case 'week':
      return 'Weekly';
    case 'month':
      return 'Monthly';
    case 'year':
      return 'Yearly';
    default:
      return '-';
  }
};

export const paidWithValueToFriendlyString = (paidWith?: string | null) => {
  switch (paidWith?.toLowerCase()) {
    case 'normalcard':
      return 'Normal Card';
    case 'hsaorfsacard':
      return 'HSA/FSA Card';
    default:
      return '-';
  }
};

/**
 * This takes router.query as input and coerces the types from:
 * string | string[] | undefined
 * to:
 * string | undefined
 */
export const parseRouterQuery = (queryParams: ParsedUrlQuery): StringDict => {
  const params: StringDict = {};
  for (const key in queryParams) {
    const value = queryParams[key];

    if (Array.isArray(value)) {
      console.warn(
        `Query param ${key} is not a string, casting ${value} to string`
      );
    }
    // This casts string[] values to string by joining with ',' but leaves string values unmodified:
    params[key] = `${value}`;
  }
  return params;
};
