import { useOrderDetailsQuery } from '@/common/graphql';
import { parseRouterQuery } from '@/common/utils/Utils';
import resources from '@/globals/constants';
import { Flex, HStack, Image, Text } from '@chakra-ui/react';
import Link from 'next/link';
import { NextRouter, withRouter } from 'next/router';

interface NavigationProps {
  router: NextRouter;
}

interface SingleTabProps {
  name: string;
  href: string;
  isDone: boolean;
  isVisible: boolean;
}

const SingleTab = ({
  name = '',
  href = '',
  isDone = false,
  isVisible = false,
}: SingleTabProps) => {
  if (!isVisible) {
    return null;
  }

  return (
    <Link href={href}>
      <Flex color={'gray.900'} _hover={{ color: 'blue.500' }}>
        {isDone ? (
          <Image
            src={resources.images.progressCircleDone}
            alt="intake"
            mr={2}
          />
        ) : (
          <Image src={resources.images.progressCircle} alt="intake" mr={2} />
        )}
        <Text>{name}</Text>
      </Flex>
    </Link>
  );
};

const DEFAULT_CHECKOUT_TABS = [
  {
    path: '/checkout/intro',
    name: 'Intro',
    display: true,
  },
  {
    path: '/checkout/payment',
    name: 'Payment',
    display: true,
  },
  {
    path: '/checkout/intake-form',
    name: 'Evaluation',
    display: true,
  },
  {
    path: '/checkout/result',
    name: 'Result',
    display: false,
  },
];

const ALTERNATIVE_CHECKOUT_TABS = [
  {
    path: '/checkout/intro',
    name: 'Intro',
    display: true,
  },
  {
    path: '/checkout/intake-form',
    name: 'Evaluation',
    display: true,
  },
  {
    path: '/checkout/alternative-payment',
    name: 'Payment',
    display: true,
  },
  {
    path: '/checkout/result',
    name: 'Result',
    display: false,
  },
];

const Navigation = ({ router }: NavigationProps) => {
  const { pathname } = router;
  const { orderId = '' } = parseRouterQuery(router.query);
  const { data } = useOrderDetailsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      businessId: orderId,
    },
    skip: !orderId,
  });
  if (!data) {
    return null;
  }

  let tabs;
  if (
    pathname.startsWith('/alternative-payment') ||
    data?.order?.useAlternativeCard
  ) {
    tabs = ALTERNATIVE_CHECKOUT_TABS;
  } else {
    tabs = DEFAULT_CHECKOUT_TABS;
  }
  const currentActiveTabIndex = tabs.findIndex((tab) =>
    pathname.startsWith(tab.path)
  );

  return (
    <HStack as="nav">
      {tabs.map((tab, tabIndex) => (
        <SingleTab
          href={`${tab.path}/${orderId}`}
          name={tab.name}
          key={tabIndex}
          isDone={currentActiveTabIndex >= tabIndex + 1}
          isVisible={tab.display}
        />
      ))}
    </HStack>
  );
};

export const CheckoutNavBar = withRouter(Navigation);
