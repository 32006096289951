import { MerchantNavTabs } from '@/components/navigation/authenticated/MerchantNavTabs';
import { CheckoutNavBar } from '@/components/navigation/checkout/CheckoutNavBar';
import { useRouter } from 'next/router';

export default function Navigation(): React.ReactElement | null {
  const router = useRouter();
  const { pathname } = router;
  const isCheckout = router.pathname.includes('checkout');

  if (isCheckout) {
    return <CheckoutNavBar />;
  } else if (pathname.startsWith('/merchants')) {
    return <MerchantNavTabs />;
  } else {
    // TODO - add nav bar for other pages
    return <div />;
  }
}
