import { Flex, Link, Text } from '@chakra-ui/react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

interface NavTabProps {
  title: string;
  tabId: string;
  href: string;
}

export const NavTab: React.FunctionComponent<NavTabProps> = ({
  title,
  tabId,
  href,
}) => {
  const router = useRouter();
  const isSelected = router.pathname.includes(tabId);

  return (
    <Flex
      px={4}
      justifyContent={'center'}
      alignItems="flex-end"
      borderBottomWidth={2}
      borderBottomColor={isSelected ? 'blue.600' : 'transparent'}
      textAlign={'center'}
    >
      <Link
        as={NextLink}
        color={isSelected ? 'blue.600' : 'gray.600'}
        _hover={{ color: 'blue.600', textDecoration: 'none' }}
        href={href}
      >
        <Text
          lineHeight={'md'}
          fontWeight={400}
          fontSize={'md'}
          pb={4}
          textAlign={'center'}
        >
          {title}
        </Text>
      </Link>
    </Flex>
  );
};
