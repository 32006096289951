import * as Sentry from '@sentry/nextjs';

const enableSentry =
  process.env.APP_ENV === 'production' || process.env.APP_ENV === 'dev';

/** Forwards given error to sentry when the site is built in production mode */
export const captureException = (error: Error, scope?: Sentry.Scope) => {
  if (enableSentry) {
    Sentry.captureException(error, scope);
  } else {
    console.error(error);
  }
};

/**
 * Adds breadcrumb to add context to errors when they occur
 * https://docs.sentry.io/product/issues/issue-details/breadcrumbs/
 */
export const addBreadcrumb = (breadcrumb: Sentry.Breadcrumb) => {
  if (enableSentry) {
    Sentry.addBreadcrumb(breadcrumb);
  } else {
    console.log(breadcrumb);
  }
};
