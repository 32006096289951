import { Flex } from '@chakra-ui/react';
import { NavTab } from './NavTab';

export const MerchantNavTabs: React.FunctionComponent = () => {
  return (
    <Flex flexDirection={'row'} flex={1} h={'100%'} pb={'1px'}>
      <NavTab
        title={'Payments'}
        tabId={'payments'}
        href={'/merchants/payments'}
      />
      <NavTab
        title={'Qualifications'}
        tabId={'qualifications'}
        href={'/merchants/qualifications'}
      />
      <NavTab title={'Leads'} tabId={'leads'} href={'/merchants/leads'} />
      <NavTab
        title={'Promotional messaging'}
        tabId={'promotional-messaging'}
        href={'/merchants/promotional-messaging'}
      />
    </Flex>
  );
};
