export const HEADER_HEIGHT = '110px';

const images = {
  dottedCheck: '/dotted-check.svg',
  logo: '/truemed-logo-navy.svg',
  payment: '/payment.svg',
  intake: '/intake.svg',
  evalution: '/evalution.svg',
  progressCircle: '/progress-circle.svg',
  progressCircleDone: '/progress-circle-done.svg',
  card: '/card.svg',
  onboardingBg: '/onboarding-bg.svg',
  provider: '/provider-icon.png',
  plusGraphic: '/plus-graphic.svg',
};

const resources = {
  images,
};

// https://www.w3resource.com/javascript/form/email-validation.php
export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export default resources;
