import * as Logo from '@/components/MainLogo';
import { Flex } from '@chakra-ui/react';
import Navigation from './Navigation';

// Import using alias to prevent next from throwing error from importing non-default exported component.
const MainLogo = Logo.MainLogo;

const HeaderLayout = () => {
  return (
    <Flex
      as="header"
      top="0"
      left="0"
      right="0"
      w="full"
      h="72px"
      bg="white"
      justifyContent="space-between"
      px={{ base: 2, md: 6 }}
    >
      <Flex>
        <MainLogo clickable={false} />
      </Flex>
      <Flex>
        <Navigation />
      </Flex>
    </Flex>
  );
};

export default HeaderLayout;
