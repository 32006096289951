var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const enableSentry =
  process.env.APP_ENV === 'production' || process.env.APP_ENV === 'dev';
if (enableSentry) {
  const SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

  Sentry.init({
    environment: process.env.APP_ENV || 'production',
    // Note - this is evaluated on the browser so we must inject the git commit hash
    // into the app at build time to propagate the value from server to browser.
    release: process.env.NEXT_GIT_COMMIT || 'unknown',
    dsn:
      SENTRY_DSN ||
      'https://a5c0a5f5926c44148bf152ccd650b850@o4504674015838208.ingest.sentry.io/4504674023047168',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
}
