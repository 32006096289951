import resources from '@/globals/constants';
import { Box, HStack, Image } from '@chakra-ui/react';
import Link from 'next/link';

export function MainLogo({ clickable = true }) {
  const component = (
    <HStack spacing="12px" justifyContent="center">
      <Box py={{ base: 4, md: 8 }}>
        <Image
          height={{ base: '16px', md: '24px' }}
          maxH={'100%'}
          src={resources.images.logo}
          alt="Truemed"
        />
      </Box>
    </HStack>
  );
  return clickable ? <Link href={'/'}>{component}</Link> : component;
}
