import { Box, Flex } from '@chakra-ui/react';

const FooterLayout = () => {
  return (
    <Flex as="footer" w="full" padding="16px" align="center" justify="center">
      <Box textAlign="center">
        <Box fontWeight="600">© True Medicine Inc. All Rights Reserved.</Box>
        <Box fontWeight="light">Copyright &copy; 2023</Box>
      </Box>
    </Flex>
  );
};

export default FooterLayout;
