import { RefreshTokenDocument, RefreshTokenMutation } from '@/common/graphql';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';

export class JwtManager {
  private token: string | undefined;
  private refreshToken: string | undefined;
  private apolloClient?: ApolloClient<NormalizedCacheObject>;

  constructor(
    token?: string | undefined,
    refreshToken?: string | undefined,
    apolloClient?: ApolloClient<NormalizedCacheObject>
  ) {
    this.token = token;
    this.refreshToken = refreshToken;
    this.apolloClient = apolloClient;
  }

  getToken() {
    return this.token;
  }

  getRefreshToken() {
    return this.refreshToken;
  }

  setToken(token: string) {
    this.token = token;
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  setApolloClient(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient;
  }

  async renewToken() {
    if (!this.apolloClient) {
      throw new Error('Apollo client not initialized');
    }
    const response = await this.apolloClient.mutate<RefreshTokenMutation>({
      mutation: RefreshTokenDocument,
      variables: {
        refreshToken: this.refreshToken,
      },
    });
    return response?.data?.refreshToken;
  }
}

// This is a global instance of JwtManager that should only be used in client-side code
export const globalJwtManager = new JwtManager();
