import { IS_SERVER } from '@/common/constants/AppConstants';
import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { globalJwtManager, JwtManager } from './JwtManager';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const createAuthLink = (jwtManager?: JwtManager) =>
  new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: jwtManager?.getToken()
          ? `JWT ${jwtManager.getToken()}`
          : '',
      },
    }));
    return forward(operation);
  });

function createApolloClient(jwtManager?: JwtManager) {
  const authLink = createAuthLink(jwtManager);
  const httpLink = createUploadLink({
    uri: process.env.NEXT_PUBLIC_CANONICAL_API_URL,
    credentials: 'same-origin', // Additional fetch() options like `credentials` or `headers`
  });
  return new ApolloClient({
    ssrMode: IS_SERVER,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });
}

export function initializeApollo(initialState = null, jwtManager?: JwtManager) {
  const _apolloClient =
    apolloClient ??
    (IS_SERVER
      ? createApolloClient(jwtManager)
      : createApolloClient(jwtManager || globalJwtManager));

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
    const data = merge(existingCache, initialState, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (IS_SERVER) {
    return _apolloClient;
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }

  return _apolloClient;
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps?: Record<string, any>
) {
  if (pageProps) {
    pageProps[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pageProps;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApollo(pageProps?: { [x: string]: any }) {
  const state = pageProps?.[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
