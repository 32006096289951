import { loadSession } from '@/common/AuthApi';
import { IS_SERVER } from '@/common/constants/AppConstants';
import { SessionDocument } from '@/common/graphql';
import {
  addApolloState,
  initializeApollo,
  useApollo,
} from '@/common/lib/ApolloClient';
import { globalJwtManager } from '@/common/lib/JwtManager';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { toBusinessPageName } from '@/features/tracking/TrackingUtils';
import '@/styles/globals.css';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { StepsTheme } from 'chakra-ui-steps';
import { NextComponentType } from 'next';
import App, { AppContext, AppInitialProps, AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { ReactElement, useEffect, useRef } from 'react';
import { MixpanelAPI } from '../common/lib/Mixpanel';
import MainLayout from './layouts/MainLayout';

const CustomSteps = {
  ...StepsTheme,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  baseStyle: (props: any) => {
    return {
      ...StepsTheme.baseStyle(props),
      stepContainer: {
        ...StepsTheme.baseStyle(props).stepContainer,
        // your custom styles here
      },
      labelContainer: {
        ...StepsTheme.baseStyle(props).labelContainer,
        // your custom styles here
        flex: 1,
      },
    };
  },
};

const theme = extendTheme({
  fonts: {
    heading: 'PP Neue Montreal',
    body: 'PP Neue Montreal',
  },
  styles: {
    global: {
      'html, body': {
        fontWeight: 500,
      },
    },
  },
  components: {
    Steps: CustomSteps,
  },
  colors: {
    background: '#F9FAFF',
    blue: {
      50: '#F9FAFF',
      100: '#CFD8F5',
      200: '#A5B6EA',
      300: '#7A94E0',
      400: '#5072D5',
      500: '#2650CB',
      600: '#2247AD',
      700: '#1A3570',
      800: '#1A3570',
      900: '#162C52',
    },
  },
});

type CustomNextComponentType = {
  getLayout?: () => ReactElement;
} & NextComponentType;

interface AppOwnProps {
  token?: string;
  refreshToken?: string;
}

interface MainLayoutProps extends AppProps, AppOwnProps {
  Component: CustomNextComponentType;
}

export default function MyApp({
  Component,
  pageProps,
  token,
  refreshToken,
}: MainLayoutProps) {
  const trackedRef = useRef(false);
  if (!IS_SERVER && token && refreshToken) {
    globalJwtManager.setToken(token);
    globalJwtManager.setRefreshToken(refreshToken);
  }

  const apolloClient = useApollo(pageProps);
  const getLayout =
    Component.getLayout ||
    ((page: ReactElement | ReactElement[]) => <MainLayout>{page}</MainLayout>);

  const { pathname } = useRouter();

  useEffect(() => {
    if (!IS_SERVER && !trackedRef.current) {
      MixpanelAPI.track(`Page - ${toBusinessPageName(pathname)} - Init`);
      trackedRef.current = true;
    }
  }, [pathname]);

  return (
    <ChakraProvider theme={theme}>
      <ApolloProvider client={apolloClient}>
        {getLayout(
          <ErrorBoundary pathname={pathname}>
            <Component {...pageProps} />
          </ErrorBoundary>
        )}
      </ApolloProvider>
    </ChakraProvider>
  );
}

MyApp.getInitialProps = async (
  context: AppContext
): Promise<AppInitialProps & AppOwnProps> => {
  const props = (await App?.getInitialProps(context)) ?? {};

  // getInitialProps is also called on every page transition
  // currently, we only want to have it called on the first page load (server-side)
  if (!IS_SERVER) {
    return { ...props };
  }

  // load session
  const loadSessionResult = await loadSession(context.ctx.req?.headers.cookie);
  const { token, session } = loadSessionResult;
  if (!token) {
    return { ...props };
  }

  // write session to apollo cache
  const apolloClient = initializeApollo();
  apolloClient.writeQuery({
    query: SessionDocument,
    data: {
      session,
    },
  });
  addApolloState(apolloClient, props.pageProps);

  return { ...props, token, refreshToken: session?.refreshToken };
};
