import { Box, Flex } from '@chakra-ui/react';
import { ReactElement } from 'react';
import FooterLayout from './FooterLayout';
import HeaderLayout from './HeaderLayout';

interface MainLayoutProps {
  children: ReactElement | ReactElement[];
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <Flex flexDir="column" height="100vh">
      <HeaderLayout />
      <Flex
        flex={1}
        mt={10}
        w={'full'}
        padding="16px"
        justifyContent={'center'}
      >
        <Box minH={`calc(100vh - 290px)`} flex="1">
          {children}
        </Box>
      </Flex>
      <FooterLayout />
    </Flex>
  );
};

export default MainLayout;
